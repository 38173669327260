import React from "react";
import KiedImages from "../../../../../../assets/images/kied.images.component";
import Masonry from "react-responsive-masonry";
import "../InnovationDetails.css";
import parse from "html-react-parser";
import useScreenBreakPoint from "../../../../../../hooks/useScreenBreakPoint";
import PaymentListing from "../../../../../../components/payment-cards/paymantlisting";
function InnovationEventDescription(props) {
  let width = useScreenBreakPoint();

  const height = [100, 100, 300];
  return (
    <div>
      <div>
        <div className="faq_banner pl-2 d-flex head-container">
          <a href="/">Home&nbsp;/</a>
          <a href="/department/inovation/inovationEvents"> Innovation&nbsp;/</a>
          <a className="current" href="">
            Event-Details
          </a>
        </div>
      </div>
      {/* ///////////// */}
      <div className="head-container">
        <div className="row mx-0 event-row">
          <div className="col-lg-6 col-md-6 col-sm-12  ">
            <div
              className={`d-flex justify-content-start  ${
                width === "xs" || width === "sm" ? "sml mb-2 " : "mt-5 mb-4"
              }`}
            >
              <h1 className="event-detail-heading">{props.data.title}</h1>
            </div>
            <div className="">
              {props.data.mainDescription &&
                props.data.mainDescription !== "" && (
                  <div>
                    <div className="event-detail-para d-flex justify-content-center ">
                      <div className="admin-editor">
                        {props.data.mainDescription
                          ? parse(props.data.mainDescription)
                          : "--"}
                      </div>
                    </div>
                    {/* <div className="Schemes_heading_description mb-3">
                    {props.data.maindescription}
                  </div>
                  <div className="description_heading_news">
                    {props.data.maindescription2heading}
                  </div>
                  <div className="Schemes_heading_description mb-3">
                    {props.data.maindescription2}
                  </div> */}
                  </div>
                )}
              {/* <div className=" mt-4 d-flex flex-column align-items-start justify-content-center mb-3">
                <div className="Schemes_heading_description">
                  <div className="m-1">
                    <b className="sub-titles-event">Duration :&nbsp;&nbsp;</b>
                    {props.data.duration}
                  </div>
                  <div className="m-1">
                    <b className="sub-titles-event">
                      Category of Participants:&nbsp;&nbsp;
                    </b>
                    {props.data.CategoryOfParticipants}
                  </div>
                  <div className="m-1">
                    <b className="sub-titles-event">
                      Number of Participants:&nbsp;&nbsp;
                    </b>
                    {props.data.NoOfParticipants}
                  </div>
                </div>
              </div> */}

              {props.data.listitem && props.data.listitem !== "" && (
                <div className="mt-3 mb-3">
                  <div className="description_heading_news">
                    {props.data.listheading}
                  </div>
                  <ul className="Schemes_heading_description">
                    {props.data.listitem.map((list, i) => (
                      <li className="ml-4">{list}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div
              className={`  ${
                width === "xs" || width === "sm"
                  ? "sml "
                  : "mt-3 mb-2 pb-2 py-4 "
              }`}
            >
              {props.data.images && props.data.images !== "" && (
                <div
                  className={` ${
                    width === "xs" || width === "sm" ? "sml mt-3" : "pt-3 mb-5"
                  }`}
                >
                  <Masonry columnsCount={2} gutter="10px">
                    {props.data.images.map((image, i) => (
                      <KiedImages
                        link={image.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                          // height: "220px",
                          object: "cover",
                          border: "solid 1px grey ",
                          boxShadow: "1px 1px 5px 3px grey",
                        }}
                      />
                    ))}
                  </Masonry>
                </div>
              )}
            </div>
          </div>
          {props?.data?.status === "Upcoming" && (
            <div className="w-100">
              <div className="event-detail-heading d-flex align-items-center justify-content-center pb-3">
                Fee Structure
              </div>
              <div className="d-flex justify-content-center w-100 ">
                <PaymentListing
                  title={"Regular"}
                  amount={props.data.fees}
                  data={props.data}
                  description={
                    "Make payment to successfully register to the event."
                  }
                  event={true}
                />
              </div>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InnovationEventDescription;

import React from "react";

import SmallBanner from "../../components/small.banner/small.banner";
import TenderTable from "./sections/tender-table/tender.table.section";
import "./tenders.page.styles.css";
import { getMaterial } from "../../apis/publicApis";
import { useState, useEffect } from "react";

function MaterialsPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getMaterial()
      .then((res) => {
        
        let prdata = [];
        res?.data?.forEach((item) => {
          
          let prItem = {
            title: item?.description,
            file: item?.file,
          };
          prdata.push(prItem);
        });
        setData(prdata);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <SmallBanner title="Materials" />

      <TenderTable data={data} />
    </div>
  );
}

export default MaterialsPage;

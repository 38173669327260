import React from "react";
import SmallBanner from "../../../../../components/small.banner/small.banner";
import "./InnovationDetails.css";
import InnovationEventDescription from "./sections/InnovationEventDescription";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { inovationEventData } from "../../inovationData";
import { getNews } from "../../../../../apis/publicApis";


function InnovationStatEventDetails() {
  const [isActive, setIsActive] = useState(true);
  const param = useParams();
  const [data, setData] = useState([]);
  const [newsData, setNewsData] = useState([])

  useEffect(() => {
    getNews()
      .then((res) => {
    
        setNewsData(res.data.filter((item, i) => item.id == param.id));
      })
      .catch(() => {});
  }, []);



  useEffect(() => {
    let selected = inovationEventData.filter((item) => {
   
      return item.id == param.id;
    });
    setData(selected[0]);
  }, [param.id]);
 
  return (
    <div>
      <SmallBanner />
      <InnovationEventDescription data={data} />
    </div>
  );
}

export default InnovationStatEventDetails;

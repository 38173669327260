import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { headerMenus } from "../header.config";

import KiedIcons from "../../../assets/icons/kied.icons.component";
import KiedImages from "../../../assets/images/kied.images.component";
import { getMenu } from "../../../apis/publicApis";
import { toast } from "react-toastify";
import { useLangStore } from "../../../utils/zustand";

function HeaderMenuComponent({ isScrolled }) {
  const { language } = useLangStore();
  const [clicked, setClicked] = React.useState(false);
  const [menuData, setMenuData] = useState(headerMenus);
  const [count, setCount] = useState(1);
  const [dept, setDept] = useState(null);

  const generateId = (id, id2) => {
    let genId = `3`;
    if (id2) {
      genId = genId + id + id2;
    } else {
      genId = genId + id;
    }

    return Number(genId);
  };
  const generateSubmenus = (id) => {
    setDept(id);
    let newArray = [
      {
        id: `${generateId(id)}1`,
        label: "Schemes",
        path: `/department/inovation/${id}/inovationSchemes`,
      },
      {
        id: `${generateId(id)}2`,
        label: "Events",
        path: `/department/inovation/${id}/inovationEvents`,
      },
      {
        id: `${generateId(id)}3`,
        label: "Programs",
        path: `/department/inovation/${id}/inovationPrograms`,
      },
    ];
    return newArray;
  };

  const getMenus = () => {
    getMenu()
      .then((res) => {
      
        let departmentData = res.data.filter((item) => item.active === 1) || [];

        let modifiedData = departmentData.map((item) => ({
          id: Number(generateId(item.id)),

          label: item.name,
          hasSubMenus: true,
          path: `/department/inovation/${item.id}/home`,
          subMenus: generateSubmenus(item.id),
        }));
        modifiedData.push({
          id: 999,

          label: "Enterprise Development Center",
          hasSubMenus: false,
          path: `/department/enterprise-development`,
          
        });
        let menuArray = headerMenus;
        menuArray.forEach((item) => {
          if (item.id === 3) {
            item["menus"] = modifiedData;
          }
        });
    
        setMenuData(() => menuArray);
        setCount((c) => c + 1);
      })
      .catch((err) => {
     
       
      });
  };
  useEffect(() => {
    getMenus();
  }, []);



  return (
    <>
      <div
        className={
          isScrolled
            ? "menu-container head-container pl-4 pr-3"
            : "menu-container head-container pl-4 pr-3 sticky"
        }
        style={{ marginTop: "-20px" }}
        id="flex-header"
      >
        <ul id="menu" className="" key={count}>
          {menuData.map((headers) => {
          
            return (
              <li
                key={`${headers.id} - ${count}`}
                className={`parent-menus ${headers.right ? "float-right" : ""}`}
                onMouseEnter={() => {
                  setClicked(false);
                }}
              >
                <Link
                  to={headers.path ?? "#"}
                  className={headers.type === "logo" ? "p-0" : ""}
                >
                  {headers.type === "logo" ? (
                    <KiedImages
                      image="kied-logo"
                      className="kied_mobile_logo"
                    />
                  ) : (
                    headers[language]
                  )}
                  {headers.hasMenus && (
                    <span className="header-down-icon-container">
                      <KiedIcons
                        icon="arrow-down"
                        className="menu-dropdown-icon"
                      />
                    </span>
                  )}
                </Link>
                {!clicked && headers.hasMenus && (
                  <ul>
                    {headers.menus.map((menu) => (
                      <li key={menu.id} className="sub-menus">
                        <Link
                          to={menu.path ?? "#"}
                          onClick={() => setClicked(true)}
                        >
                          {language !== "ma"
                            ? menu[language] ?? menu.label
                            : menu.label}
                        
                        </Link>
                      
                        {menu.hasSubMenus && (
                          <ul>
                            {menu.subMenus.map((submenu) => (
                              <li key={submenu.id}>
                                <Link
                                  to={submenu.path ?? "#"}
                                  onClick={() => setClicked(true)}
                                >
                                  {submenu.label}
                                </Link>
                                {submenu.hasSubMenus && (
                                  <ul>
                                    {submenu.subMenus.map((subsubmenu) => (
                                      <li key={subsubmenu.id}>
                                        <Link
                                          to={subsubmenu.path ?? "#"}
                                          onClick={() => setClicked(true)}
                                        >
                                          {subsubmenu.label}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default HeaderMenuComponent;

import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./scheme.card.style.css";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import KiedButton from "../buttons/button/button.component";
import { useNavigate } from "react-router-dom";
function SchemeCard(props) {
  let width = useScreenBreakPoint();
  let navigate = useNavigate();
  return (
    <div
      className={` col-md-12 col-sm-12 scheme_image schemes-col p-0 w-100  ${
        props.index > 1
          ? "d-flex align-content-between justify-content-center"
          : "d-flex align-content-between justify-content-center  "
      } ${width === "sm" || width === "xs" ? "pb-4 px-0" : ""}`}
    >
      <div id="scheme_inner" className="p-3 scheme_card w-100">
        <div id="scheme_head" className=" d-flex justify-content-between ">
          <div id="scheme_head_index" className="">
            <div className="ribbon1">
              <span>{props.data.status}</span>
            </div>
          </div>
        </div>
        <div id="scheme_content" className=" ">
          <div id="scheme_title" className="text-dark pb-3">
            <h5>{props.data.name}</h5>
          </div>
          <div
            id="scheme_description"
            className="inovation_text mb-3 text-dark clamp-text"
          >
            {props.data.description}
          </div>
        </div>
        <div className="mt-2  mb-2">
          <KiedButton
            className={`readmore_btn pt-3${props.index > 1 ? "pb-4" : "pt-0"}`}
            primary
            rounded
            content={
              <a
                className="mt-4 ml-1 event_readmore"
                onClick={(e) => navigate(`/schemes/${props.data.id}`)}
              >
                Learn More
              </a>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SchemeCard;

import React from "react";
import SmallBanner from "../../../../components/small.banner/small.banner";
import parse from "html-react-parser";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import "../../../inovation/sections/Events/staticdetail/InnovationDetails.css";
import KiedImages from "../../../../assets/images/kied.images.component";

function HomeNewsDetailPage(props) {
  let width = useScreenBreakPoint();

  const height = [100, 100, 300];
  return (
    <>
      <div>
        {/* <div>
          <div className="faq_banner pl-2 d-flex head-container">
            <a href="/">Home&nbsp;/</a>
            <a href="/department/inovation/inovationEvents">
              {" "}
              Innovation&nbsp;/
            </a>
            <a className="current" href="">
              News-Details
            </a>
          </div>
        </div> */}
        {/* ///////////// */}
        <div className="head-container">
          <div className="row mx-0 event-row">
            <div className="col-lg-6 col-md-6 col-sm-12  ">
              <div
                className={`d-flex justify-content-start  ${
                  width === "xs" || width === "sm" ? "sml mb-2 " : "mt-5 mb-4"
                }`}
              >
                <h1 className="event-detail-heading">{props.data.title}</h1>
              </div>
              <div className="">
                {props.data.designation && props.data.designation !== "" && (
                  <div>
                    <div className="event-detail-para d-flex flex-column justify-content-center ">
                      {props.data.designation
                        ? parse(props.data.designation)
                        : "--"}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="">
                  <KiedImages className="w-100 " link={props.data.photo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeNewsDetailPage;

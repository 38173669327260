
import moment from "moment";



export const isDate = function (date) {
	try {
		if (date instanceof Date) {
			return date.isValid();
		}
		return moment(date, "DD-MM-YYYY").isValid();
	} catch (error) {
		return false;
	}

// 
};


export const parseQueryParams = (params = {}) => {
	if (!Object.entries(params).length) return "";
	return Object.entries(params).reduce((acc, [key, value]) => acc + `${key}=${value}&`, "?");
};
export const parseExpQueryParams = (params = {}) => {
	if (!Object.entries(params).length) return "";
	return Object.entries(params).reduce((acc, [key, value]) => acc + `${key}=${value}&`, "");
};

export const formatFormErrors = (params = {}) => {
	if (!Object.entries(params).length) return "";
	return Object.entries(params).reduce((acc, [key, value]) => acc + `${key}=${value},`, "");
};
export const FormatError = (error) => {
	var errorString = error.response.data.message;

	if (
		typeof error.response.data.error === "object" &&
		!Array.isArray(error.response.data.error) &&
		error.response.data.error !== null
	) {
		errorString = Object.keys(error.response.data.error)
			.map((key) => {
			
				return error.response.data.error[key];
			})
			.join("\n");
	} else {
		errorString = error.response.data.message;
	}
	return errorString;
};

import React, { useState } from "react";
import Expanded from "../../../../components/Icons/Expanded";
import NotExpanded from "../../../../components/Icons/NotExpanded";
import ProgramListing from "../../../../components/programListing/program.listing.card";

const groupByYear = (data) => {
  console.log(data);
  const grouped = data.reduce((acc, card) => {
    const year = new Date(card.date).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(card);
    return acc;
  }, {});

  const currentYear = new Date().getFullYear();

  // Sort keys and add "Last Year - Present Year" label
  const sortedKeys = Object.keys(grouped)
    .sort((a, b) => b - a) // Descending order for years
    .map((year, index) => ({
      year: parseInt(year),
      label: index === 0 ? `${year} ` : `${parseInt(year) - 1} - ${year}`,
    }));

  // Construct the sortedGroupedData
  const sortedGroupedData = sortedKeys.reduce((acc, { year, label }) => {
    acc[label] = grouped[year].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    return acc;
  }, {});

  return sortedGroupedData;
};

function ProgramListingDetails({ data }) {
  const groupedData = groupByYear(data);
  const [expandedYear, setExpandedYear] = useState(null);

  const toggleAccordion = (year) => {
    setExpandedYear(expandedYear === year ? null : year);
  };

  return (
    <div className="auto-container my-4">
      {Object.keys(groupedData).map((label) => (
        <div key={label}>
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(label)}
            style={{
              cursor: "pointer",
              backgroundColor: "#f1f1f1",
              padding: "10px",
              marginBottom: "5px",
              borderRadius: "5px",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ width: "33.33%", color: "#f8463f", fontSize: "22px" }}>
              {label}
            </h3>
            <p
              style={{
                width: "33.33%",
                color: "#f8463f",
                marginBottom: "0px",
                fontSize: "22px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {console.log(label, typeof label, label == "2025")}
              {label == 2025 ? "Upcoming Programs" : "Training Calendar"}
            </p>
            <span
              style={{
                width: "33.33%",
                color: "#f8463f",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {expandedYear === label ? (
                <span>
                  <Expanded />
                </span>
              ) : (
                <span>
                  <NotExpanded />
                </span>
              )}
            </span>
          </div>

          {expandedYear === label && (
            <div className="accordion-body">
              {groupedData[label].map((card, i) => (
                <ProgramListing
                  data={card}
                  key={i}
                  index={i}
                  type={"ProgramList"}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ProgramListingDetails;

/////////////////////////////////////////////////
import AOS from "aos";
import React from "react";
import ReactDOM from "react-dom/client";
////////////////////Style Imports////////////////
// import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import "./styles/bootstrap.css";
import "./styles/buttons.style.css";
import "./styles/footer.style.css";
import "./styles/header.style.css";
import "./styles/icons.style.css";
import "./styles/main.style.css";

import "./styles/footer.style.css";

/////////////////////////////////////////////////
//////////// Routing Component //////////////////
import AppRouter from "./AppRouter";
/////////////////////////////////////////////////
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ClickJack from "./clickJack";
/////////////////////////////////////////////////
AOS.init();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {window.self === window.top ? (
      <>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </>
    ) : (
      <ClickJack />
    )}
  </React.StrictMode>
);


reportWebVitals();

import { refreshToken } from "../config";
import apis from "./api.actions";

export const registerUser = (data) =>
  apis.createData(`v1/register`, data).then((res) => res);
export const createYearlyData = (data) =>
  apis.createData(`v1/edc-yearly-data`, data).then((res) => res);
export const logoutUser = () => apis.createData(`v1/logout`).then((res) => res);
export const logedUser = () =>
  apis.getDatas(`v1/loggeduser`).then((res) => res);
export const getYearlyData = () =>
  apis.getDatas(`v1/edc-yearly-data`).then((res) => res);
export const getDistricts = () =>
  apis.getDatas(`v1/district/list`).then((res) => res);
export const resetPassword = (data) =>
  apis.createData(`v1/reset-password-confirm`, data).then((res) => res);
export const resetAdminPassword = (data) =>
  apis.createData(`v1/admin/reset-password-confirm`, data).then((res) => res);
export const forgotPassword = (data) =>
  apis.createData(`v1/reset-url`, data).then((res) => res);
export const verifyToken = (token) =>
  apis.getDatas(`v1/verify-token?token=${token}`).then((res) => res);
export const loginUser = (data) =>
  apis.createData(`v1/login`, data).then((res) => {
    
    refreshToken(res.token);
    localStorage.setItem("mv_token", res.token);
    return res;
  });

import React, { forwardRef, useImperativeHandle } from "react";
import Swal from "sweetalert2";
import RadioButtonDash from '../../../../components/radiobutton-dash/radiobutton';
import SelectBoxDash from '../../../../components/selectbox-dash/SelectBox.component';
import TextFieldDash from '../../../../components/textfield-dash/TextField.component';
import { KiedCarriers, castCategory, gender, radiooption } from '../../../edc.registration/registrationform.data';
import { districts, institutions, highestqualification, religion } from '../../../registration/registrationform.data';
import FileUploadDash from "../../../../components/file.upload-dash/fileupload.component";
const UniversityRegistrationForm = ({
  handleChange,
  handleTextFieldChange,
  formdata,
  errorSize,
  error, setError,
  fielderror,
  setSubmitted,
  handleSubmit,
  setCompleted,
  setFieldError,
  fileUpload,
 
}, ref) => {

  const requiredFields = [
    "organization_name",
    "address",
    "head_name",
    "head_designation",
    "head_email",
    "head_contact",
    "name_single_poc",
    "designation_single_poc",
    "email_single_poc",
    "contact_single_poc",
    "institution_type",

    "area_of_expertise",
    "authorization_letter",
    ...(formdata?.institution_type === "Others" ? ["details"] : []),
  ];
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      dataSetter();
    },
  }));


  const validate = () => {
    let errorField = {};
    requiredFields.forEach((item) => {
      if (
        formdata[item] === null ||
        formdata[item] === undefined ||
        formdata[item] === ""
      ) {
        errorField[item] = "Please fill in the details";
        setSubmitted(false);
      }
    });
    return errorField;
  };
  const dataSetter = () => {
    setSubmitted(true);

    let payload = new FormData();
      if (formdata?.organization_name !== "" && formdata?.organization_name) {
        payload.append("organization_name", formdata?.organization_name);
      }

      if (formdata?.address !== "" && formdata?.address) {
        payload.append("address", formdata?.address);
      }
      if (formdata?.head_name !== "" && formdata?.head_name) {
        payload.append("head_name", formdata?.head_name);
      }
      if (formdata?.head_designation !== "" && formdata?.head_designation) {
        payload.append("head_designation", formdata?.head_designation);
      }
      if (formdata?.head_email !== "" && formdata?.head_email) {
        payload.append("head_email", formdata?.head_email);
      }
      if (formdata?.head_contact !== "" && formdata?.head_contact) {
        payload.append("head_contact", formdata?.head_contact);
      }
      if (formdata?.name_single_poc !== "" && formdata?.name_single_poc) {
        payload.append("name_single_poc", formdata?.name_single_poc);
      }
      if (
        formdata?.designation_single_poc !== "" &&
        formdata?.designation_single_poc
      ) {
        payload.append(
          "designation_single_poc",
          formdata?.designation_single_poc
        );
      }
      if (formdata?.email_single_poc !== "" && formdata?.email_single_poc) {
        payload.append("email_single_poc", formdata?.email_single_poc);
      }
      if (formdata?.contact_single_poc !== "" && formdata?.contact_single_poc) {
        payload.append("contact_single_poc", formdata?.contact_single_poc);
      }
      if (formdata?.institution_type !== "" && formdata?.institution_type) {
        payload.append("institution_type", formdata?.institution_type);
      }

      if (formdata?.details !== "" && formdata?.details) {
        payload.append("details", formdata?.details);
      }
      if (formdata?.area_of_expertise !== "" && formdata?.area_of_expertise) {
        payload.append("area_of_expertise", formdata?.area_of_expertise);
      }
      if (
        formdata?.authorization_letter !== "" &&
        formdata?.authorization_letter
      ) {
        payload.append("authorization_letter", formdata?.authorization_letter);
      }

    const errorField = validate();
    if (Object.keys(errorField).length === 0) {
      handleSubmit(payload);
    } else {
      setFieldError(errorField);
      setSubmitted(false);
      setCompleted(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields (Mandatory fields are marked in red)",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };
 
  return (
    <div>

<div>
                    <div className="">
                      <TextFieldDash
                        label="Institute/ Organization Name"
                        required
                        error={fielderror.organization_name}
                        name="organization_name"
                        value={formdata?.organization_name ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <TextFieldDash
                        label="Address"
                        required
                        error={fielderror.address}
                        name="address"
                        type="textarea"
                        value={formdata?.address ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <TextFieldDash
                        label="Name of Head of the Institution"
                        required
                        error={fielderror.head_name}
                        name="head_name"
                        value={formdata?.head_name ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <TextFieldDash
                        label="Designation of Head of the Institution"
                        required
                        error={fielderror.head_designation}
                        name="head_designation"
                        value={formdata?.head_designation ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <TextFieldDash
                        label="E-mail of Head of the Institution"
                        required
                        error={fielderror.head_email}
                        type="email"
                        name="head_email"
                        value={formdata?.head_email ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />

                      <TextFieldDash
                        label="Contact Number of Head of the Institution"
                        required
                        error={fielderror.head_contact}
                        type="phone"
                        maxLength={10}
                        minLength={10}
                        name="head_contact"
                        value={formdata?.head_contact ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <hr />
                      <br />
                      <TextFieldDash
                        label="Name of Single Point of Contact at the Institution"
                        required
                        error={fielderror.name_single_poc}
                        name="name_single_poc"
                        value={formdata?.name_single_poc ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <TextFieldDash
                        label="Designation of Single Point of Contact"
                        required
                        error={fielderror.designation_single_poc}
                        name="designation_single_poc"
                        value={formdata?.designation_single_poc ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <TextFieldDash
                        label="E-mail of Single Point of Contact"
                        required
                        error={fielderror.email_single_poc}
                        type="email"
                        name="email_single_poc"
                        value={formdata?.email_single_poc ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <TextFieldDash
                        label="Contact Number of Single Point of Contact"
                        required
                        error={fielderror.contact_single_poc}
                        type="phone"
                        maxLength={10}
                        minLength={10}
                        name="contact_single_poc"
                        value={formdata?.contact_single_poc ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <SelectBoxDash
                        label="Type of Institution"
                        required
                        error={fielderror.institution_type}
                        options={institutions}
                        value={formdata?.institution_type}
                        selectionMessage=""
                        name="institution_type"
                        handleChange={handleChange}
                      />
                      <br />
                      {formdata?.institution_type === "Others" && (
                        <>
                          <TextFieldDash
                            label="Give Details on the Institution Type"
                            required
                            error={fielderror.details}
                            name="details"
                            value={formdata?.details ?? ""}
                            onChange={handleTextFieldChange}
                          />
                          <br />
                        </>
                      )}
                      <TextFieldDash
                        label="Area of Expertise of the organization"
                        required
                        error={fielderror.area_of_expertise}
                        name="area_of_expertise"
                        value={formdata?.area_of_expertise ?? ""}
                        onChange={handleTextFieldChange}
                      />
                      <br />
                      <FileUploadDash
                        label={
                          <span>
                            Authorization Letter from Head of the Institution{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/files/a_letter.pdf`}
                              target="_blank"
                            >
                              Click here to download format of the letter
                            </a>
                            (.pdf, .docx, .jpg format files are allowed)
                          </span>
                        }
                        required
                        error={fielderror.authorization_letter}
                        accept=".pdf, .docx, .jpg"
                        name="authorization_letter"
                        errorSize={errorSize?.authorization_letter}
                        type="file"
                        // filename={formdata?.gst_file_name}
                        // value={formdata?.gst_file ?? null}
                        onChange={fileUpload}
                      />

                      {error.proof && (
                        <p id="proof" className="ml-3 error">
                          Unsupported file type! Please select a .pdf or .docx
                          file
                        </p>
                      )}
                      <br />

                    </div>
                  </div>
    </div>
  );
};

export default forwardRef(UniversityRegistrationForm);

import React from "react";
import DashInfo from "../../../../components/dashinfo/dashInfo";
import KiedButton from "../../../../components/buttons/button/button.component";

function AspiringDataForm({ handlepopupEdit, handleEdit, ...props }) {
  
  return (
    <>
      <div>
        <div className="">
          <DashInfo title="Date of Birth" value={props.formdata.dob ?? ""} />
          <DashInfo title="Gender" value={props.formdata.gender ?? ""} />
          <DashInfo title="Religion" value={props.formdata.religion} />

          <DashInfo
            title="Specify Religion"
            value={props.formdata.specify_religion ?? ""}
          />

          <DashInfo title="Caste" value={props.formdata.caste ?? ""} />
          <DashInfo
            title="Highest Qualification"
            value={props.formdata.qualification}
          />
          <DashInfo title="Address" value={props.formdata.address ?? ""} />
          <DashInfo title="District" value={props.formdata.district} />
          <DashInfo
            title="Business Idea"
            value={props.formdata.business_idea ?? ""}
          />
          <DashInfo
            title="Investment Capability of the Applicant (in Lakhs)"
            value={props.formdata.investment_capability ?? ""}
          />
          <DashInfo
            title="Ever Attended a KIED Programme"
            value={
              props.formdata.is_attended_kied_program == "1" ? "Yes" : "No"
            }
          />
          {props.formdata.is_attended_kied_program == 1 && (
            <DashInfo
              title="Specify the Program Name"
              value={props.formdata.program_details_attended ?? ""}
            />
          )}

          <DashInfo
            title="How did you come to know about KIED"
            value={props.formdata.how_you_know_kied}
          />

          <br />
          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleEdit()}
              primary
              rounded
              className="mx-3"
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Edit
                </div>
              }
            />
            <KiedButton
            onClick={() => handlepopupEdit()}
            primary
            className="mx-3"
            rounded
            content={
              <div className="registerbtn d-flex justify-content-center">
                Home
              </div>
            }
          />
          </div>
        </div>
      </div>
    </>
  );
}

export default AspiringDataForm;

import React from "react";
import "../../ContactUs.css";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import TextField from "../../../../components/textfield/TextField.component";
import { useState } from "react";
import { contact } from "../../../../apis/publicApis";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
function ContactUsDetails() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [formdata, setFormdata] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (value, name) => {
    setFormdata({ ...formdata, [name]: value });
  };
  const handleregister = () => {
    let payload = new FormData();
    payload.append("first_name", formdata.first_name);
    payload.append("last_name", formdata.last_name);
    payload.append("phone_number", formdata.phone_number);
    payload.append("email", formdata.email);
    payload.append("message", formdata.message);
  
    if (
      formdata.first_name !== "" &&
      formdata.first_name &&
      formdata.phone_number !== "" &&
      formdata.phone_number &&
      formdata.email !== "" &&
      formdata.email &&
      formdata.message !== "" &&
      formdata.message
    ) {
      contact(payload)
        .then((res) => {
          setSubmitted(true);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your query is successfully submitted. Please check your mail for the reply from KIED.",
            confirmButtonColor: "#ffa93c",
            confirmButtonText: "Ok"
          });
          navigate("/");
        })
        .catch((err) => {
          setSubmitted(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.message,
            confirmButtonColor: "#ffa93c",
            confirmButtonText: "Ok"
          });
        });
    } else {
      setSubmitted(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the details",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok"
      });
    }
  };
  return (
    <div>
      {/* jghjgh */}
      <div className="contactus-details auto-container">
        <div className="contactus-heading">
          <div className="col-12 d-flex">
            <div className="col-1" ></div>
          <h2 className="">GET IN TOUCH</h2>
          </div>
          
          <div className="row details-section">
            <div className="col-lg-4 col-md-4 col-sm-12 icon-details">
              <div className="icon-section d-flex col-12">
                <KiedIcons icon="location-btn" className="contact-icons col-2" />
                <div className="pl-3">
                  <h3>Enterprise Development Centre (EDC),</h3>
                  <p>
                    4th Floor, Inkel Tower 1,
                    <br />
                    Inkel Business Park, Angamaly, Ernakulam
                  </p>
                  <br />
                  <h3>
                    Kerala Institute for Entrepreneurship Development (KIED)
                  </h3>
                  <p>
                    Rockwell Road, HMT Colony PO,
                    <br />
                    Kalamassery, Cochin - 683 503
                  </p>
                </div>
                
              </div>
              <div className="pl-3 mt-3">
                  
                </div>
              {/* <div className="icon-section d-flex">
                <KiedIcons icon="location-btn" className="contact-icons" />
                <div className="pl-3">
                  <h3>
                    Kerala Institute for Entrepreneurship Development (KIED)
                  </h3>
                  <p>
                    Rockwell Road, HMT Colony PO,
                    <br />
                    Kalamassery, Cochin - 683 503
                  </p>
                </div>
              </div> */}
              <div className="icon-section d-flex col-12">
                <KiedIcons icon="phone-btn" className="contact-icons-phn col-2" />
                <div className="pl-3">
                  <h3>Phone Number</h3>
                  <p>0484 2550322</p>
                </div>
              </div>
              <div className="icon-section d-flex col-12">
                <KiedIcons icon="email-btn" className="contact-icons-email  col-2" />
                <div className="pl-3">
                  <h3>Email Address</h3>
                  <p>info@kied.in </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 ">
              <div className="contact-form">
                <div className="row">
                  <div className="col-6">
                    <TextField
                      type="text"
                      placeholder="First name"
                      name="first_name"
                      hidelabelStyle
                      value={formdata.first_name ?? ""}
                      className="field-address w-100"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      type="text"
                      name="last_name"
                      value={formdata.last_name ?? ""}
                      placeholder="Last name"
                      hidelabelStyle
                      className="field-address w-100"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <TextField
                    type="number"
                    name="phone_number"
                    value={formdata.phone_number ?? ""}
                    placeholder="Phone number"
                    maxLength = {10}
                    minLength = {10}
                    hidelabelStyle
                    className="input-details w-100"
                    onChange={handleChange}
                  />
                  <TextField
                    type="email"
                    name="email"
                    value={formdata.email ?? ""}
                    placeholder="Email"
                    hidelabelStyle
                    className="input-details w-100"
                    onChange={handleChange}
                  />
                  <TextField
                    type="textarea"
                    name="message"
                    value={formdata.message ?? ""}
                    placeholder="Messages"
                    hidelabelStyle
                    className="input-details-two w-100"
                    onChange={handleChange}
                  />
                </div>
                <div className="contact-btn d-flex justify-content-center ">
                  <KiedButton
                    disabled={submitted === true}
                    onClick={() => handleregister()}
                    content={
                      <>
                        <div className="d-flex">
                          <div>Send Message</div>
                        </div>
                      </>
                    }
                    rounded
                    secondary
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsDetails;

import React, { useEffect, useState } from "react";

import { CSSTransition } from "react-transition-group";
import FooterComponent from "./footer/footer.component";
import HeaderComponent from "./header/header.component";
let timeout;
let scroll = 0;
function LayoutComponent(props) {

	const topFunction = () => {
		window.scrollTo(0, 0);
	};
	const [showTopButton, setShowTopButton] = useState(false);
	useEffect(() => {
		window.onscroll = () => {
			if (timeout) {
				clearTimeout(timeout);
			}
			timeout = setTimeout(() => {
				if (scroll >= window.scrollY && window.scrollY > 10) {
					setShowTopButton(true);
				} else if (window.scrollY === 0) {
					setShowTopButton(false);
				} else {
					setShowTopButton(true);
				}
			}, 10);
		};
	}, []);
	return (
		<div className="position-relative">
			<CSSTransition in={showTopButton} timeout={300} classNames="alert" unmountOnExit>
				<div id="scrollbutton" className="scroll-to-top " onClick={() => topFunction()}>
					<span className="icon fa fa-angle-double-up"></span>
				</div>
			</CSSTransition>

			<HeaderComponent isScrolled={showTopButton} {...props} />
			<div id="body">
				{props.children}

				<FooterComponent />
			</div>
		</div>
	);
}

export default LayoutComponent;

import React from "react";
import "./success.card.styles.css";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import { Link } from "react-router-dom";

function PaymentFailedCard(props) {

  return (
    <div className="auto-container d-flex justify-content-center success ">
      <div className="success_card ">
        <div className="success_title pt-4 pb-2 d-flex justify-content-center">
          <h3 className="success_title pt-4 pb-2 d-flex justify-content-center">
            {props?.order_status === "Aborted" ? "Aborted" : "Payment Failed"}
          </h3>
        </div>

        <div className="  d-flex justify-content-center pb-5 w-100 ">
          <KiedIcons className=" error_font" />
        </div>
        <div className="success_sub_title  pb-5 px-3 d-flex justify-content-center">
          {props.error === "Aborted" ? (
            <h4>{props.error} </h4>
          ) : (
            <h4>
              {props.error
                ? props.error
                : "Something went wrong, Please try again later."}
            </h4>
          )}
        </div>
        {/* <div className="success_desc d-flex justify-content-center pb-5">
          <h6>
            
          </h6>
        </div> */}
        <div className="d-flex justify-content-center pb-5">
          <Link to="/">
            <KiedButton
              secondary
              rounded
              inverted
              content={
                <div className="d-flex justify-content-center">Back</div>
              }
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailedCard;

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import {
  logedUser,
  loginUser,
  registerUser,
  forgotPassword,
  getDistricts,
} from "../../apis/auth.apis";
import RadioButton from "../../components/radiobutton/radiobutton";
import { roleData } from "./roles.data";

import LoaderComponent from "../../components/loader/loader.component";
import SmallBanner from "../../components/small.banner/small.banner";
import { Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Accordion } from "react-bootstrap";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";

import "./auth.style.css";
import KiedImages from "../../assets/images/kied.images.component";
import { useLoginState } from "../../utils/zustand";
import Swal from "sweetalert2";
import { useLoginStore } from "../../utils/zustand";


function ManageAccount() {
  ////////////////////////////////////////////////////////////////////////////
  const [show, setShow] = useState(false);
  const [keyField, setKeyField] = useState(0);

  const { setIsLogin } = useLoginStore();
  const loginState = useLoginState();
  let width = useScreenBreakPoint();
  useEffect(() => {
    sessionStorage.setItem("auth_try", 0);
  }, [!sessionStorage.getItem("auth_try")]);

  const handleClose = () => {
    setShow(false);
    setPerror(null);
  };
  const handleVerification = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    let payload = {
      email: formData?.email ?? "",
      
    };
  
    setIsActive(true);
    setSuccess(null);
    forgotPassword(payload)
      .then((res) => {
        setSuccess(res.message);
        setIsActive(false);
        handleClose();
        setSuccess(res.message);
        setError(null);
      })
      .catch((err) => {
        setPerror(err.response.data.message);
        setIsActive(false);
        setSuccess(null);
      });
  };
  //////////////////////////////////////////////////////////

  let props = useOutletContext();
  const HandleForgot = () => {
    setShow(true);
    setError(null);
    setSuccess(null);
  };

  const getRole = () => {
    switch (loginState.nextuserType) {
      case "aspering_ent":
        return "2";
      case "schools":
        return "3";
      case "research":
        return "5";
      case "existing_ent":
        return "4";
      case "industry_asso":
        return "6";
      case "mentors":
        return "7";
      case "edclub":
        return "8";
      case "students":
        return "9";
      case "guest":
        return "10";

      default:
        return " ";
    }
  };

  const [activeSection, setActiveSection] = React.useState("signup");
  const [validated, setValidated] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [headerTitle, setHeaderTitle] = React.useState("Register");
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = React.useState({ role: getRole() });
  const [mobError, setMobError] = useState("");
  const [waError, setWaError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [iswa, setIswa] = useState(true);
  const [isag, setIsag] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const history = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    let payload = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      ...(formData.whatsapp_no && formData.whatsapp_no !== "" && !iswa
        ? { whatsapp_no: formData.whatsapp_no }
        : { whatsapp_no: formData?.mobile }),
      password_confirmation: formData.password_confirmation,
      role: formData.role,
      district_id: formData.district_id,
      mobile: formData.mobile,
      whatsapp_no:
        formData.whatsapp_no !== "" && formData.whatsapp_no
          ? formData.whatsapp_no
          : formData.mobile,
    };
    
    setError(null);
    if (payload.password === payload.password_confirmation) {
      setIsActive(true);
      registerUser(payload)
        .then((res) => {
          setError(null);
          
          Swal.fire({
            icon: "success",
            title: "Registered Successfully",
            text:
              "A verification email has been sent to your mail." +
              " " +
              res.message,

            confirmButtonColor: "#ffa93c",
            confirmButtonText: "Ok",
          }).then((result) => {
            history("/");
          });
          
          setIsag(false);
          setFormData({ role: getRole(), password_confirmation: "" });
         

          setIsActive(false);
         

        
        })
        .catch((err) => {
          setError(err.response.data.message);
          setIsActive(false);
         

          setSuccess(null);
        });
    } else {
      setError("Password does not match");
    }
  };

  const districts = () => {
    getDistricts()
      .then((res) => {
       
        let districts = [];
        res?.data?.forEach((item) => {
          let districtItem = {
            name: item?.district_name,
            value: item?.id,
          };
          districts.push(districtItem);
        });
        setDistrictData(districts);
        
      })
      .catch((err) => {
      
      });
  };
  useEffect(() => {
    districts();
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    let tries = sessionStorage.getItem("auth_try")
      ? JSON.parse(sessionStorage.getItem("auth_try"))
      : 0;
  

    setError(null);
    let payload = {
      email: formData.email,
      password: formData.password,
      ...(tries > 5 ? { lock: true } : { lock: false }),
    };

    setIsActive(true);
    
    loginUser(payload)
      .then((res) => {
        loginState.resetUserType();
        getUserData();
        sessionStorage.setItem("auth_try", 0);
        setIsLogin(true);
      })
      .catch((err) => {
        if (err?.response?.status == 403) {
          tries = tries + 1;
          sessionStorage.setItem("auth_try", tries);
        } else {
          sessionStorage.setItem("auth_try", 0);
        }
        setError(err.response.data.message);
        setIsActive(false);
        setSuccess(null);
      });
  };
 
  const getRolePlaceHolder = () => {
    switch (loginState.nextuserType) {
      case "aspering_ent":
        return "Name of Applicant";
      case "schools":
        return "Name of School/College";
      case "research":
        return "Name of Research Institution/University";
      case "existing_ent":
        return "Name of Applicant";
      case "industry_asso":
        return "Name of Industry Association";
      case "mentors":
        return "Name of Applicant";
      case "edclub":
        return "Name of Institution";
      case "mentors":
        return "Name of Applicant";

      default:
        return "Name of Applicant";
    }
  };
  const getUserData = () => {
    logedUser()
      .then((res) => {
        let next_path = localStorage.getItem("next_path") || "/dashboard";
        localStorage.setItem("user", JSON.stringify(res.user));
       
        props.Login();
        history(next_path);
       
        loginState.resetUserType();
        setIsActive(false);
      })
      .catch((err) => {
        setIsActive(false);
       
      });
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    var emailsonly = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
   
    if (name === "mobile") {
      value = e.target.value.replace(/\D/g, "");
      if (value.length !== 10) {
        setMobError("Please enter a valid mobile number");
      } else {
        setMobError("");
      }
    }
    if (name === "whatsapp_no") {
      value = e.target.value.replace(/\D/g, "");
      if (value.length !== 10) {
        setWaError("Please enter a valid mobile number");
      } else {
        setWaError("");
      }
    }
    if (name === "email") {
      value = e.target.value;
      if (!emailsonly.test(value)) {
        setEmailError("Please enter a valid email");
      } else {
        setEmailError("");
      }
    }

    if (name === "role") {
      switch (value) {
        case "2":
          loginState.setNextUserType("aspering_ent");
          break;
        case "3":
          loginState.setNextUserType("schools");
          break;
        case "5":
          loginState.setNextUserType("research");
          break;
        case "4":
          loginState.setNextUserType("existing_ent");
          break;
        case "6":
          loginState.setNextUserType("industry_asso");
          break;
        case "7":
          loginState.setNextUserType("mentors");
          break;
        case "8":
          loginState.setNextUserType("edclub");
          break;
        case "9":
          loginState.setNextUserType("students");
          break;
        case "10":
          loginState.setNextUserType("guest");
          break;
        default:
          loginState.resetUserType();
          break;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return width === "xs" || width === "sm" ? (
    <>
      <LoaderComponent open={isActive} />
     
      <Accordion
        defaultActiveKey="0"
        className="container"
        style={{ marginTop: "150px" }}
      >
        <Accordion.Item eventKey="0" className="mb-2">
          <Accordion.Header className="banner_image directory_acordion">
            Login
          </Accordion.Header>
          <Accordion.Body
            className="login-container d-flex align-content-stretch align-items-center justify-content-center w-100"
            style={{ minHeight: "340px" }}
          >
            <div className="w-100">
              <div className="">
                {success && (
                  <div
                    className="alert-login pl-4"
                    isOpen={success ? true : false}
                  >
                    <div className="d-flex justify-content-left ">
                      {success}
                      <KiedImages className="" image="success-icon" />
                    </div>
                  </div>
                )}
                {error && (
                  <div
                    className="alert-login pl-4"
                    isOpen={error ? true : false}
                  >
                    <div className="alert-error d-flex align-items-center justify-content-left">
                      {error}
                      <KiedImages
                        className="ml-2 mb-1 alert-error-icon"
                        image="error-icon"
                      />
                    </div>
                  </div>
                )}
                <form onSubmit={handleLogin} style={{ padding: "0px 5px" }}>
                  <input
                    name="email"
                    type="email"
                    autoComplete="off"
                    value={formData?.email ?? ""}
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  <input
                    name="password"
                    type="password"
                    autoComplete="off"
                    value={formData?.password ?? ""}
                    placeholder="Password"
                    onChange={handleChange}
                  />

                  <button className="mt-4" type="submit">
                    Login{" "}
                  </button>
                  <div
                    className="forgot_button mt-3"
                    onClick={() => HandleForgot()}
                  >
                    Forgot Password?
                  </div>
                </form>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="mb-5">
          <Accordion.Header className="banner_image directory_acordion">
            Dont have an account?
          </Accordion.Header>
          <Accordion.Body>
            <div
              className="login-container d-flex align-content-stretch align-items-center justify-content-center w-100"
              style={{ minHeight: "440px" }}
            >
              <div className="w-100 mt-4 mb-4 ">
                <form onSubmit={handleRegister} style={{ padding: "0px 5px" }}>
                  <div className="d-flex justify-content-left w-100">
                    {error && (
                      <div
                        className="alert-login"
                        isOpen={error ? true : false}
                      >
                        <div className="alert-error d-flex align-items-center justify-content-left">
                          {error}
                          <KiedImages
                            className="ml-2 mb-1 alert-error-icon"
                            image="error-icon"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <input
                    name="name"
                    type="text"
                    placeholder={getRolePlaceHolder()}
                    // autoComplete="off"
                    required
                    value={formData.name ?? ""}
                    onChange={handleChange}
                  />

                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    // autoComplete="off"
                    value={formData.email ?? ""}
                    required
                    onChange={handleChange}
                  />
                  {emailError && (
                    <span
                      className="w-100 text-left"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {emailError}
                    </span>
                  )}
                  <input
                    name="mobile"
                    type="text"
                    value={formData.mobile ?? ""}
                    // autoComplete="off"
                    pattern="[0-9]*"
                    required
                    minLength={10}
                    maxLength={10}
                    placeholder="Phone Number"
                    onChange={handleChange}
                  />
                  {mobError && (
                    <span
                      className="w-100 text-left"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {mobError}
                    </span>
                  )}
                  <div className="d-flex justify-content-between w-100  my-2">
                    <p className="m-0 ">Is this your WhatsApp Number?</p>
                    <div className="d-flex justify-content-around w-100 pl-2 col-3">
                      <label className="d-flex justify-content-center align-items-center">
                        <input
                          type="radio"
                          checked={iswa === true}
                          name="yes"
                        />{" "}
                        <span className="px-2">Yes</span>
                      </label>
                      <label className="d-flex justify-content-center align-items-center">
                        <input
                          type="radio"
                          checked={iswa === false}
                          name="no"
                          onChange={() => {
                            setIswa(false);
                            setKeyField((k) => k + 1);
                          }}
                        />
                        <span className="px-2">No</span>
                      </label>
                    </div>
                  </div>
                  {iswa === false && (
                    <>
                      <input
                        name="whatsapp_no"
                        type="text"
                        value={formData.whatsapp_no ?? ""}
                        pattern="[0-9]*"
                        required
                        minLength={10}
                        maxLength={10}
                        placeholder="WhatsApp Phone Number"
                        onChange={handleChange}
                      />
                      {waError && (
                        <span
                          className="w-100 text-left"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {waError}
                        </span>
                      )}
                    </>
                  )}

                  <select
                    name="role"
                    onChange={handleChange}
                    value={formData.role}
                  >
                    <option value=" ">Select Role</option>
                    {roleData.map((role, i) => (
                      <option value={role.value}>{role.name}</option>
                    ))}
                   
                  </select>
                  <select
                    name="district_id"
                    onChange={handleChange}
                    value={formData.district_id}
                  >
                    <option value="">Select District</option>
                    {districtData.map((district, i) => {
                      return (
                        <option value={district.value}>{district.name}</option>
                      );
                    })}
                  </select>
                  <input
                    name="password"
                    type="password"
                    // autoComplete="off"
                    required
                    value={formData.password ?? ""}
                    minLength={8}
                    placeholder="Password Minimum 8 Characters"
                    onChange={handleChange}
                  />
                  <input
                    name="password_confirmation"
                    type="password"
                    // autoComplete="off"
                    required
                    value={formData.password_confirmation}
                    placeholder="Confirm Password"
                    onChange={handleChange}
                  />
                  {formData.password !== formData.password_confirmation && (
                    <span
                      className="w-100 text-left"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      Password does not match
                    </span>
                  )}
                  <div className="d-flex  w-100  my-2">
                    <div className="d-flex justify-content-start w-100 p-0 col-1">
                      <label className="d-flex">
                        <input
                          type="checkbox"
                          checked={isag === true ? true : false}
                          defaultChecked={isag === true ? true : false}
                          onChange={() => {
                            setIsag((f) => !f);
                            setKeyField((k) => k + 1);
                          }}
                          name="agree"
                        />{" "}
                      </label>
                    </div>
                    <p className="m-0">
                      <span style={{ fontSize: "14px" }}>
                        I have read and agree to the{" "}
                      </span>

                      <a
                        href={`${process.env.PUBLIC_URL}/files/privacypolicy.pdf`}
                        target="_blank"
                        style={{ color: "blue" }}
                      >
                        privacy policy
                      </a>
                    </p>
                  </div>

                  <button className="mt-3" type="submit">
                    Register
                  </button>
                </form>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/*///////////////////////////////// forgot password popup ///////////////////////////////////////////////*/}
      <div className="">
        <Modal
          className="forgot_password_popup w-100"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="forgot_title w-100 d-flex justify-content-center">
              Forgot Password ?
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleVerification} validated={validated}>
            <Modal.Body>
              {perror && (
                <div className="alert-login" isOpen={perror ? true : false}>
                  <div className="alert-error d-flex align-items-center">
                    {perror}
                    <KiedImages
                      className="ml-2 mb-1 alert-error-icon"
                      image="error-icon"
                    />
                  </div>
                </div>
              )}

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoFocus
                  required
                  onChange={handleChange}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button className="forgot_button_secondary" onClick={handleClose}>
                Go Back
              </Button>
              <Button className="forgot_button_primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
      {/*///////////////////////////////// forgot password popup close ///////////////////////////////////////////////*/}

      
    </>
  ) : (
    <>
      <LoaderComponent open={isActive} />
      <SmallBanner title={headerTitle} />
      <div className="position-relative">
        <div className="container">
          {" "}
          <div
            className={`login-container ${
              activeSection === "signup" ? "right-panel-active" : ""
            }`}
            id="login-container"
          >
            <div className="form-login-container sign-up-login-container  ">
              <form onSubmit={handleRegister}>
                <div className="d-flex justify-content-left w-100">
                  {error && (
                    <div className="alert-login" isOpen={error ? true : false}>
                      <div className="alert-error d-flex align-items-center justify-content-left">
                        {error}
                        <KiedImages
                          className="ml-2 mb-1 alert-error-icon"
                          image="error-icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <input
                  name="name"
                  type="text"
                  // autoComplete="off"
                  autoFill="off"
                  placeholder={getRolePlaceHolder()}
                  required
                  value={formData.name ?? ""}
                  onChange={handleChange}
                />
                <input
                  name="email"
                  type="email"
                  // autoComplete="off"
                     autoFill="off"
                  placeholder="Email"
                  value={formData.email ?? ""}
                  required
                  onChange={handleChange}
                />
                {emailError && (
                  <span
                    className="w-100 text-left"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {emailError}
                  </span>
                )}
                <input
                  name="mobile"
                  type="text"
                  // autoComplete="off"
                     autoFill="off"
                  value={formData.mobile ?? ""}
                  pattern="[0-9]*"
                  required
                  minLength={10}
                  maxLength={10}
                  placeholder="Phone Number"
                  onChange={handleChange}
                />
                {mobError && (
                  <span
                    className="w-100 text-left"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {mobError}
                  </span>
                )}
                <div className="d-flex w-100 align-items-center my-2">
                  <p className="m-0">Is this your WhatsApp Number?</p>
                  <div className="d-flex align-items-center pl-2">
                    <label className="d-flex">
                      <input
                        type="radio"
                        checked={iswa === true}
                        onChange={() => {
                          setIswa(true);
                          setKeyField((k) => k + 1);
                        }}
                        name="yes"
                      />{" "}
                      <span className="px-2">Yes</span>
                    </label>
                    <label className="d-flex justify-content-center">
                      <input
                        type="radio"
                        checked={iswa === false}
                        name="no"
                        onChange={() => {
                          setIswa(false);
                          setKeyField((k) => k + 1);
                        }}
                      />
                      <span className="px-2">No</span>
                    </label>
                  </div>
                </div>
                {iswa === false && (
                  <>
                    <input
                      name="whatsapp_no"
                      type="text"
                         autoFill="off"
                      value={formData.whatsapp_no ?? ""}
                      pattern="[0-9]*"
                      required
                      minLength={10}
                      maxLength={10}
                      placeholder="WhatsApp Phone Number"
                      onChange={handleChange}
                    />
                    {waError && (
                      <span
                        className="w-100 text-left"
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {waError}
                      </span>
                    )}
                  </>
                )}
                <select
                  name="role"
                  onChange={handleChange}
                     autoFill="off"
                  value={formData.role}
                >
                  <option value=" ">Select Role</option>
                  {roleData.map((role, i) => (
                    <option value={role.value} key={i}>
                      {role.name}
                    </option>
                  ))}
                  
                </select>
                <select
                  name="district_id"
                  onChange={handleChange}
                  value={formData.district_id}
                >
                  <option value="">Select District</option>
                  {districtData.map((district, i) => {
                    return (
                      <option value={district.value}>{district.name}</option>
                    );
                  })}
                </select>
                <input
                  name="password"
                  type="password"
                  // autoComplete="off"
                     autoFill="off"
                  required
                  value={formData.password ?? ""}
                  minLength={8}
                  placeholder="Password Minimum 8 Characters"
                  onChange={handleChange}
                />
                <input
                  name="password_confirmation"
                  type="password"
                  // autoComplete="off"
                     autoFill="off"
                  required
                  value={formData.password_confirmation}
                  placeholder="Confirm Password"
                  onChange={handleChange}
                />

                {formData.password !== formData.password_confirmation &&
                  formData.password_confirmation && (
                    <span
                      className="w-100 text-left"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      Password does not match
                    </span>
                  )}
                <div className="d-flex w-100 align-items-center my-2">
                  <div className="d-flex align-items-center pl-2">
                    <label className="d-flex">
                      <input
                        checked={isag === true ? true : false}
                        defaultChecked={isag === true ? true : false}
                        type="checkbox"
                        onChange={() => {
                          setIsag((f) => !f);
                          setKeyField((k) => k + 1);
                        }}
                        name="agree"
                      />{" "}
                      <span className="px-2"></span>
                    </label>
                  </div>
                  <p className="m-0">
                    I have read and agree to the{" "}
                    <a
                      href={`${process.env.PUBLIC_URL}/files/privacypolicy.pdf`}
                      target="_blank"
                      style={{ color: "blue" }}
                    >
                      privacy policy
                    </a>
                  </p>
                </div>
                <button
                  disabled={isag === false ? true : false}
                  className="mt-3"
                  type="submit"
                >
                  Register
                </button>
              </form>
            </div>
            <div className="form-login-container sign-in-login-container">
              <form onSubmit={handleLogin}>
                <div className="d-flex justify-content-left w-100">
                  {error && (
                    <div className="alert-login" isOpen={error ? true : false}>
                      <div className="alert-error d-flex align-items-center justify-content-left">
                        {error}
                        <KiedImages
                          className="ml-2 mb-1 alert-error-icon"
                          image="error-icon"
                        />
                      </div>
                    </div>
                  )}
                  {success && (
                    <div
                      className="alert-login "
                      isOpen={success ? true : false}
                    >
                      <div className=" alert-success d-flex justify-content-left align-items-center">
                        {success}
                        <KiedImages
                          className="ml-1 success-icon"
                          image="success-icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  value={formData?.email ?? ""}
                  onChange={handleChange}
                />
                <input
                  name="password"
                  type="password"
                  autoComplete="off"
                  value={formData?.password ?? ""}
                  placeholder="Password"
                  onChange={handleChange}
                />

                <button className="mt-3" type="submit">
                  Login{" "}
                </button>
                <div
                  className="forgot_button mt-3"
                  onClick={() => HandleForgot()}
                >
                  Forgot Password?
                </div>
              </form>
              {/*///////////////////////////////// forgot password popup ///////////////////////////////////////////////*/}
              <div className="">
                <Modal
                  className="forgot_password_popup w-100"
                  show={show}
                  onHide={handleClose}
                >
                  <Modal.Header>
                    <Modal.Title className="forgot_title w-100 d-flex justify-content-center">
                      Forgot Password ?
                    </Modal.Title>
                  </Modal.Header>
                  <Form onSubmit={handleVerification} validated={validated}>
                    <Modal.Body>
                      {perror && (
                        <div
                          className="alert-login"
                          isOpen={perror ? true : false}
                        >
                          <div className="alert-error d-flex align-items-center justify-content-left">
                            {perror}
                            <KiedImages
                              className="ml-2 mb-1 alert-error-icon"
                              image="error-icon"
                            />
                          </div>
                        </div>
                      )}
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Email"
                          autoFocus
                          required
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                      <Button
                        className="forgot_button_secondary"
                        onClick={handleClose}
                      >
                        Go Back
                      </Button>
                      <Button className="forgot_button_primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Modal>
              </div>
              {/*///////////////////////////////// forgot password popup close ///////////////////////////////////////////////*/}
            </div>
            <div className="overlay-login-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h4 className="pb-4">Already have an Account ?</h4>

                  <button
                    className="ghost"
                    onClick={(e) => {
                      setFormData({});
                      setError(null);
                      setHeaderTitle("Login");
                      setActiveSection("signin");
                    }}
                  >
                    Login
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h4 className="pb-4">Don't have an Account ?</h4>

                  <button
                    className="ghost"
                    onClick={(e) => {
                      setFormData({ role: "2" });
                      setError(null);
                      setHeaderTitle("Register");
                      setActiveSection("signup");
                    }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageAccount;

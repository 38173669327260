import React, { forwardRef, useImperativeHandle } from "react";
import Swal from "sweetalert2";
import RadioButtonDash from '../../../../components/radiobutton-dash/radiobutton';
import SelectBoxDash from '../../../../components/selectbox-dash/SelectBox.component';
import TextFieldDash from '../../../../components/textfield-dash/TextField.component';
import { KiedCarriers, castCategory, gender, radiooption } from '../../../edc.registration/registrationform.data';
import { districts, highestqualification, religion } from '../../../registration/registrationform.data';
const AspiringRegistrationForm = ({
  handleChange,
  handleTextFieldChange,
  formData,
  error, setError,
  fielderror,
  setSubmitted,
  handleSubmit,
  setCompleted,
  setFieldError,
}, ref) => {

  const requiredFields = [
    "dob",
    "gender",
    "religion",
    "specify_religion",
    "caste",
    "qualification",
    "address",
    "district",
    "business_idea",
    "investment_capability",
    "is_attended_kied_program",
    "how_you_know_kied",
    ...(formData?.is_attended_kied_program !== 1
      ? []
      : ["program_details_attended"]),
  ];
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      dataSetter();
    },
  }));


  const validate = () => {
    let errorField = {};
    requiredFields.forEach((item) => {
      if (
        formData[item] === null ||
        formData[item] === undefined ||
        formData[item] === ""
      ) {
        errorField[item] = "Please fill in the details";
        setSubmitted(false);
      }
    });
    return errorField;
  };
  const dataSetter = () => {
    setSubmitted(true);

    let payload = new FormData();
    if (formData.dob !== "" && formData.dob) {
      payload.append("dob", formData.dob);
    }
    if (formData.gender !== "" && formData.gender) {
      payload.append("gender", formData.gender);
    }

    if (formData.religion === "Others") {
      payload.append("religion", formData.specify_religion ?? "");
    } else {
      if (formData.religion !== "" && formData.religion) {
        payload.append("religion", formData.religion);
      }
    }

    if (formData.caste !== "" && formData.caste) {
      payload.append("caste", formData.caste);
    }
    if (formData.qualification !== "" && formData.qualification) {
      payload.append("qualification", formData.qualification);
    }
    if (formData.address !== "" && formData.address) {
      payload.append("address", formData.address);
    }
    if (formData.district !== "" && formData.district) {
      payload.append("district", formData.district);
    }
    if (formData.business_idea !== "" && formData.business_idea) {
      payload.append("business_idea", formData.business_idea);
    }
    if (
      formData.investment_capability !== "" &&
      formData.investment_capability
    ) {
      payload.append("investment_capability", formData.investment_capability);
    }
    if (formData.is_attended_kied_program !== "") {
      payload.append(
        "is_attended_kied_program",
        formData.is_attended_kied_program
      );
    }

    if (
      formData.program_details_attended !== "" &&
      formData.program_details_attended
    ) {
      payload.append(
        "program_details_attended",
        formData.program_details_attended
      );
    }
    if (formData.how_you_know_kied !== "" && formData.how_you_know_kied) {
      payload.append("how_you_know_kied", formData.how_you_know_kied);
    }
    const errorField = validate();
    if (Object.keys(errorField).length === 0) {
      handleSubmit(payload);
    } else {
      setFieldError(errorField);
      setSubmitted(false);
      setCompleted(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields (Mandatory fields are marked in red)",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <div>

      <div className="">
        <TextFieldDash
          label="Date of Birth "
          required
          error={fielderror?.dob}
          type="date"
          name="dob"
          value={formData?.dob ?? ""}
          onChange={handleTextFieldChange}
        />
        <br />

        <RadioButtonDash
          options={gender}
          label="Gender"
          required
          name="gender"
          error={fielderror?.gender}
          value={formData?.gender ?? ""}
          checked={formData?.gender}
          onChange={handleChange}
        />
        <br />
        <SelectBoxDash
          label="Religion"
          required
          options={religion}
          error={fielderror?.religion}
          value={formData?.religion}
          selectionMessage="Select your religion"
          name="religion"
          handleChange={handleChange}
        />
        <br />
        {formData?.religion === "Others" && (
          <>
            <TextFieldDash
              label="Specify Religion"
              required
              name="specify_religion"
              error={fielderror?.specify_religion}
              value={formData?.specify_religion ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
          </>
        )}
        <RadioButtonDash
          options={castCategory}
          label="Caste"
          required
          name="caste"
          checked={formData?.caste}
          error={fielderror?.caste}
          value={formData?.caste ?? ""}
          onChange={handleChange}
        />
        <br />
        <SelectBoxDash
          label="Highest Qualification"
          required
          options={highestqualification}
          error={fielderror?.qualification}
          value={formData?.qualification}
          selectionMessage="Select your Qualification"
          name="qualification"
          handleChange={handleChange}
        />
        <br />

        <TextFieldDash
          label="Address"
          required
          type="textarea"
          error={fielderror?.address}
          name="address"
          value={formData?.address ?? ""}
          onChange={handleTextFieldChange}
        />
        <br />
        <SelectBoxDash
          label="District"
          required
          error={fielderror?.district}
          options={districts}
          value={formData?.district}
          selectionMessage="Select your district"
          name="district"
          handleChange={handleChange}
        />
        <br />

        <TextFieldDash
          label="Business Idea"
          required
          error={fielderror?.business_idea}
          type="text"
          name="business_idea"
          value={formData?.business_idea ?? ""}
          onChange={handleTextFieldChange}
        />
        <br />
        <TextFieldDash
          label="Investment Capability of the Applicant (in Lakhs)"
          required
          error={fielderror?.investment_capability}
          type="number"
          name="investment_capability"
          value={formData?.investment_capability ?? ""}
          onChange={handleTextFieldChange}
        />
        <br />

        <RadioButtonDash
          options={radiooption}
          label="Ever Attended a KIED Programme?"
          required
          error={fielderror?.is_attended_kied_program}
          name="is_attended_kied_program"
          value={formData?.is_attended_kied_program ?? ""}
          checked={formData?.is_attended_kied_program ?? ""}
          onChange={handleChange}
        />
        <br />
        {formData?.is_attended_kied_program && formData?.is_attended_kied_program == 1 && (
          <>
            <TextFieldDash
              label="Specify the Program Name"
              required
              error={fielderror?.program_details_attended}
              type="text"
              name="program_details_attended"
              value={formData?.program_details_attended ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
          </>
        )}
        <SelectBoxDash
          label="How did you come to know about KIED"
          required
          error={fielderror?.how_you_know_kied}
          options={KiedCarriers}
          value={formData?.how_you_know_kied}
          selectionMessage=""
          name="how_you_know_kied"
          handleChange={handleChange}
        />
        <br />

        {error?.proof && (
          <p id="proof" className="ml-3 error">
            Unsupported file type! Please select a .pdf or .docx file
          </p>
        )}
        <br />
   
      </div>
    </div>
  );
};

export default forwardRef(AspiringRegistrationForm);

import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import LayoutComponent from "../../layout/layout.component";
import ManageAccount from "../../pages/auth/ManageAccount";
import { useLoginStore } from "../../utils/zustand";

function PrivateRoute(props) {
  let isAuthenticated = props.state.isAuthenticated;
  let loginKey = localStorage.getItem("mv_token")
  const {isLogin} = useLoginStore()

  return (
    <LayoutComponent {...props}>
      {isAuthenticated && loginKey && isLogin ? (
        <Outlet context={props} />
      ) : (
        <Navigate to="/user-management" replace />
        
      )}
    </LayoutComponent>
  );
}

export default PrivateRoute;

import React, { useState } from "react";
import KiedIcons from "../../../assets/icons/kied.icons.component";
import KiedImages from "../../../assets/images/kied.images.component";
import { headerMenus } from "../header.config";
import { useNavigate } from "react-router-dom";
import KiedButton from "../../../components/buttons/button/button.component";
import { useLangStore } from "../../../utils/zustand";

function MobileMenuCompoent(props) {
  const{language} = useLangStore()
  let isAuthenticated = props.state.isAuthenticated;
  const [actveMainMenu, setActiveMainMenu] = useState(null);
  const [actveMenu, setActiveMenu] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItemToggle, setMenuItemToggle] = useState(false);
  const [subMenuItemToggle, setSubMenuItemToggle] = useState(false);
  const navigate = useNavigate();
  const handleButton = () => {
    if (isAuthenticated) {
      props.logout();
      sessionStorage.removeItem("next_path");
    } else {
      navigate("/user-management");
    }
  };

  const handleMenuClick = (e, item, menu, subMenus) => {
    e.stopPropagation();
  
    if (!menu && item) {
      if (item.id === actveMainMenu) {
        
        setMenuItemToggle(!menuItemToggle);
      } else {
        setMenuItemToggle(true);
      }
    }
    if (menu) {
      if (menu.id === actveMenu) {
        setSubMenuItemToggle(!subMenuItemToggle);
      } else {
        setSubMenuItemToggle(true);
      }
    }
    setActiveMainMenu(item?.id ?? null);
    setActiveMenu(menu?.id ?? null);

   
  };

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  const navMobile = () => {
    navigate("/");
  };
  return (
    <div className="menu-container container-lg" id="mobile-header">
      <div className="d-flex align-items-center justify-content-center">
        <KiedImages
          image="kied-logo"
          className="kied_mobile_logo"
          onClick={() => navMobile()}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between container py-2">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <KiedButton
              className="mobile_login_button"
              onClick={(e) => handleButton()}
              content={
                <div className="d-flex align-items-center">
                  {props.state.isAuthenticated ? "Logout" : "Login"}{" "}
                </div>
              }
            />
          </div>
        </div>
        <div
          className="responsive-menu-button"
          onClick={(e) => {
            menuToggle();
          }}
        >
          <KiedIcons icon="menu-header" />
        </div>
      </div>
      {menuOpen && (
        <ul className="mob-menu-ul">
          {headerMenus.map((item) => (
            <>
              {item.type !== "logo" && (
                <li className="main-menu-bg">
                  <div className="d-flex justify-content-between">
                    <div
                      onClick={() => {
                        navigate(item.path);
                        menuToggle();
                      }}
                    >
                       {language !== "ma" ? item[language] ?? item.label  : item.label}
                      {/* {item.label} */}
                    </div>
                    {item.hasMenus && (
                      <div
                        className="menu-expand-icon"
                        onClick={(e) => handleMenuClick(e, item, null, null)}
                      >
                        <KiedIcons icon="menu-down-arrow" />
                      </div>
                    )}
                  </div>

                  {item.hasMenus &&
                    menuItemToggle &&
                    item.id === actveMainMenu && (
                      <ul className="menu-ul-padding">
                        <>
                          {item.menus.map((menu) => (
                            <li className="menu-bg">
                              <div className="d-flex justify-content-between">
                                <div
                                  onClick={() => {
                                    navigate(menu.path);
                                    menuToggle();
                                  }}
                                >
                                  {/* {menu.label} */}
                                  {language !== "ma" ? menu[language] ?? menu.label  : menu.label}
                                </div>
                                {menu.hasSubMenus && (
                                  <div>
                                    <div
                                      className="menu-expand-icon"
                                      onClick={(e) =>
                                        handleMenuClick(e, item, menu, null)
                                      }
                                    >
                                      <KiedIcons icon="menu-down-arrow" />
                                    </div>
                                  </div>
                                )}
                              </div>
                              {menu.hasSubMenus &&
                                subMenuItemToggle &&
                                menu.id === actveMenu && (
                                  <>
                                    <ul className="menu-ul-padding">
                                      {menu.subMenus.map((submenus) => (
                                        <li
                                          className="sub-menu-bg"
                                          onClick={(e) => {
                                            handleMenuClick(
                                              e,
                                              item,
                                              menu,
                                              submenus
                                            );
                                          }}
                                        >
                                          {" "}
                                          <span
                                            onClick={() => {
                                              navigate(submenus.path);
                                              menuToggle();
                                            }}
                                          >
                                            {submenus.label}
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                )}
                            </li>
                          ))}
                        </>
                      </ul>
                    )}
                </li>
              )}
            </>
          ))}
        </ul>
      )}
    </div>
  );
}

export default MobileMenuCompoent;

import React from "react";

import "./news.section.style.css";
import { newsData } from "./newsData";

import NewsCard from "../../../../components/news.cards/news.card";
import MultiCarousal from "../../../../components/carousals/multicarousals/multi.carousal";

function NewsSection(props) {


  return (
    <div className="greyBackground">
      <div className="common-sections container ">
        <div className="news-section">
          <div className="sec-title text-center">
            <h2 className="sec-heading">{props.heading}</h2>
          </div>

          <MultiCarousal section="news">
            {props?.data?.map((news, i) => {
              return <>{<NewsCard data={news} key={i} />}</>;
            })}
          </MultiCarousal>
        </div>
      </div>
    </div>
  );
}

export default NewsSection;

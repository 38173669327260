import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import TextField from "../../components/textfield/TextField.component";
import RadioButton from "../../components/radiobutton/radiobutton";
import SelectBox from "../../components/selectbox/SelectBox.component";
import KiedButton from "../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FileUpload from "../../components/file.upload/fileupload.component";
import { registeredclub } from "../../actions/api.functions";
import "./edcform.css";

import {
  KiedCarriers,
  gender,
  castCategory,
  radiooptions,
} from "../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../registration/registrationform.data";
import { developmentStage } from "./store";
import EDCFirstForm from "./edcFirstForm";
import EDCSecondForm from "./edcSecondForm";
import EDCThirdForm from "./edcThirdForm";

function EDCForm() {
  const [formdata, setFormdata] = useState({
    founder_details: [
      {
        name: "",
        qualification: "",
        experience: null,
        financial_details: "",
        pan_number: "",
        aadhar_number: "",
        address: "",
        email: "",
        contact_number: "",
      },
    ],
  });
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();

  const handleTextFieldChange = (value, name) => {
    setFormdata({ ...formdata, [name]: value });
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
   
  };

  const handleregister = () => {
  
    setActiveStep(2);
  };

  const fileUpload = (e) => {

    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;

    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        [name]: value,
        [name + "_name"]: filename,
      });
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };
  const fileFounderUpload = (e, i) => {
   
    let data = { ...formdata };
    let a = true;
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);
    const setFounderData = (a) => {
      let upData = data?.founder_details?.forEach((item, j) => {
        if (i === j) {
          if (a) {
            item[name] = value;
          } else {
            item[name] = undefined;
          }
        }
      });
      return upData;
    };

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        founder_details: setFounderData(a),
      });
    } else {
      setFormdata({
        ...formdata,
        founder_details: setFounderData(!a),
      });
      setError({ [name]: true });
    }
  };
  const handleAdd = () => {
    let data = { ...formdata };
    data?.founder_details.push({
      name: "",
      qualification: "",
      experience: null,
      financial_details: "",
      pan_number: "",
      aadhar_number: "",
      address: "",
      email: "",
      contact_number: "",
    });
    setFormdata(data);
  };

  const handleRemove = (i) => {
    let data = { ...formdata };
    data?.founder_details.splice(i, 1);
    setFormdata(data);
  };

  const handleFounderChange = (value, name, i) => {
    let data = { ...formdata };
  

    const setFounderData = () => {
      let upData = data?.founder_details?.forEach((item, j) => {
        if (i === j) {
          item[name] = value;
        }
      });
      return upData;
    };

    setFormdata({ ...data, founder_details: setFounderData() });

    setFormdata(data);
  };
 
  return (
    <>
      <SmallBanner
        title={`Enterprise Development Center Registration - ${
          activeStep === 1
            ? "General Information"
            : activeStep === 2
            ? "Document Uploads"
            : activeStep === 3
            ? "Business Plan"
            : activeStep === 4 && "Self Declaration & Payment"
        }`}
      />
      <div>
        <div className="d-flex align-items-center justify-content-between head-container">
          <div className="faq_banner  d-flex">
            <a href="/">Home&nbsp;/</a>
            <a href="/programs/enterprise-development/details">Details&nbsp;/</a>
            <a className="current">EDC Registration</a>
          </div>
          <div className=" d-flex justify-content-end mt-3">
            <p className="">
              <span>
                <span style={{ color: "red" }}>* </span> Mandatory Fields
              </span>
            </p>
          </div>
        </div>
        {activeStep === 1 && <EDCFirstForm setActiveStep={setActiveStep} />}
        {activeStep === 2 && <EDCSecondForm setActiveStep={setActiveStep} />}
        {activeStep === 3 && <EDCThirdForm setActiveStep={setActiveStep} />}

      </div>
    </>
  );
}

export default EDCForm;

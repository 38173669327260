import React from "react";
import SmallBanner from "../../../../../components/small.banner/small.banner";
import "./EdcEventDetails.css";
import EdcEventDescription from "../details/section/EdcEventDescription";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { EDCEventData } from "../../../edc.page.data";

function EdcEventDetails() {
  const param = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    let selected = EDCEventData.filter((item) => {
  
      return item.id == param.id;
    });
    setData(selected[0]);
  }, [param.id]);
 
  return (
    <div>
      <SmallBanner />
      <EdcEventDescription data={data} />
    </div>
  );
}

export default  EdcEventDetails;
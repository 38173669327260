import React, { useState } from "react";
import "./paymentListing.css";
import KiedButton from "../buttons/button/button.component";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { getPayment, getEventPayment } from "../../apis/publicApis";
import { useParams } from "react-router-dom";
import { useLoginStore } from "../../utils/zustand";
import Swal from "sweetalert2";
import moment from "moment";
function PaymentListing(props) {
  const PayLink = process.env.REACT_APP_PAYMENT_LINK;
  const [data, setData] = useState({});
  const params = useParams();
  const loggedUser = localStorage.getItem("user");
  const profileData = loggedUser ? JSON.parse(loggedUser) : null;
  const user_id = profileData?.id;
  const event_id = params.id;
  const { setIsLogin } = useLoginStore();
  const current_date = moment();
  const ebd2 = moment(props?.data?.ebd, "YYYY-MM-DD");
 

  // const router = useNavigate()
  const PaymentDetails = (amount) => {
    if (props.event === true) {
      getEventPayment({ user_id, event_id, fees: amount })
        .then((res) => {
          const bookingId = res?.booking_id;
          getPayment({ amount: amount, booking_id: bookingId })
            .then((res) => {
              
              setData(res);
              sessionStorage.setItem("tid", res.tid);
              sessionStorage.setItem("order_id", res.orderId);
             
              let payload = {
                encRequest: res.encRequest,
                access_code: res.access_code,
                tid: res.tid,
                order_id: res.orderId,
                billing_name: loggedUser.name,
                billing_email: loggedUser.email,
              };

              const form = document.createElement("form");
              form.method = "POST";
              form.action = PayLink;

              const encRequestInput = document.createElement("input");
              encRequestInput.type = "hidden";
              encRequestInput.name = "encRequest";
              encRequestInput.value = payload.encRequest;
              form.appendChild(encRequestInput);

              const accessCodeInput = document.createElement("input");
              accessCodeInput.type = "hidden";
              accessCodeInput.name = "access_code";
              accessCodeInput.value = payload.access_code;
              form.appendChild(accessCodeInput);

              document.body.appendChild(form);
              form.submit();
            })
            .catch((err) => {
             
            });
        })
        .catch((err) => {});
    } else {
      getPayment({ amount: amount })
        .then((res) => {
          setData(res);
          sessionStorage.setItem("tid", res.tid);
          sessionStorage.setItem("order_id", res.orderId);
          
          let payload = {
            encRequest: res.encRequest,
            access_code: res.access_code,
            tid: res.tid,
            order_id: res.orderId,
            billing_name: loggedUser.name,
            billing_email: loggedUser.email,
          };

          const form = document.createElement("form");
          form.method = "POST";
          form.action = PayLink;

          const encRequestInput = document.createElement("input");
          encRequestInput.type = "hidden";
          encRequestInput.name = "encRequest";
          encRequestInput.value = payload.encRequest;
          form.appendChild(encRequestInput);

          const accessCodeInput = document.createElement("input");
          accessCodeInput.type = "hidden";
          accessCodeInput.name = "access_code";
          accessCodeInput.value = payload.access_code;
          form.appendChild(accessCodeInput);

          document.body.appendChild(form);
          form.submit();
        })
        .catch((err) => {
          const error = err?.response?.data?.message;
          Swal.fire({
            icon: "error",
            title: "error",
            text: { error },
          });
        });
    }
  };
  return (
    <>
      <div className="p-3">
        <div className="payment-mainContainer d-flex flex-column justify-content-center align-items-center">
          <h1 className="payment_title_color">{props.title}</h1>
          <p className="mb-0 text-center">{props.description}</p>
          <div className="">
            {!current_date.isAfter(ebd2) && (
              <div className="col-12">
                <h3 className="" style={{ fontSize: "20px" }}>
                  <span
                    className="payment_title_color"
                    style={{ textDecoration: "line-through", fontSize: "30px" }}
                  >
                    ₹{props?.data?.dis_fees}
                  </span>
                  , <span>{props?.data?.discount}% off</span>
                </h3>
              </div>
            )}
          </div>
          <h1 className="payment_title_color ">₹{props.amount} </h1>
          <KiedButton
            onClick={
              () => PaymentDetails(props.amount)
              // (e) =>
              // router("/payment")
            }
            secondary
            small
            className="mt-2"
            type="submit"
            content={<div className=" d-flex justify-content-center">Pay</div>}
          />
        </div>
      </div>
    </>
  );
}

export default PaymentListing;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { registerIncubatte } from "../../../../actions/api.functions";
import KiedButton from "../../../../components/buttons/button/button.component";
import FileUpload from "../../../../components/file.upload/fileupload.component";
import LoaderComponent from "../../../../components/loader/loader.component";
import SelectBox from "../../../../components/selectbox/SelectBox.component";
import TextField from "../../../../components/textfield/TextField.component";
import {
  city,
  districts,
  industry,
  sectors,
} from "../../registrationform.data";

function RegistrationForms(props) {
  let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};
  const [isActive, setIsActive] = useState(false);
  const handleregister = () => {
    localStorage.removeItem("next_path");
  };
  const navigate = useNavigate();
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const [errorsize, setErrorsize] = useState({});
  
  const handleSubmit = (e) => {
    e.preventDefault();
   
    let payload = new FormData();
    payload.append("user_id", user?.id);

    payload.append("address", formdata.address);
  
    payload.append("business_plan_file", formdata.business_plan_file);
    payload.append("cin", formdata.cin);
    payload.append("city", formdata.city);
    payload.append("competetors", formdata.competetors);
    payload.append("customers_count", formdata.customers_count);
    payload.append("districts", formdata.districts);
    payload.append("email", user?.email);
    payload.append("sectors", formdata?.sectors);
    payload.append("entity_name", formdata.entity_name);
    payload.append("finacial_file", formdata.finacial_file);
    payload.append("gst_file", formdata.gst_file);
    payload.append("how_you_heard", formdata.how_you_heard);
    payload.append("incorporation_file", formdata.incorporation_file);
    payload.append("industry", formdata.industry);
    payload.append("loan_raised", formdata.loan_raised);
    payload.append("market_size", formdata.market_size);
    payload.append("mobile", formdata.mobile);
    payload.append("money_invested", formdata.money_invested);
    payload.append("no_of_employees", formdata.no_of_employees);
    payload.append("pan", formdata.pan);
    payload.append("product", formdata.product);
   

    payload.append("product_difference", formdata.product_difference);
    payload.append("product_innovations", formdata.product_innovations);
    payload.append("promoter_description", formdata.promoter_description);
    payload.append("promotor_name", formdata.promotor_name);
    payload.append("reason_of_manufactoring", formdata.reason_of_manufactoring);
    payload.append(
      "revenue_earned_financial_year",
      formdata.revenue_earned_financial_year
    );
    payload.append("revenue_earned_till", formdata.revenue_earned_till);
    payload.append("selling_tips", formdata.selling_tips);
    payload.append("support_required", formdata.support_required);
    payload.append("target_customers", formdata.target_customers);
    payload.append("how_you_heard", formdata.how_you_heard);

    setIsActive(true);
    registerIncubatte(payload)
      .then((res) => {
        setIsActive(false);
        navigate("/success");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsActive(false);
       
      });
  };
  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
   
  };

  const handleTextFieldChange = (value, name) => {
    setFormdata({ ...formdata, [name]: value });
  };

  const fileUpload = (e) => {
  
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    let filesize = e.target.files[0].size / 1024;

    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      if (filesize < 2049) {
        setFormdata({
          ...formdata,
          [name]: value,
          [name + "_name"]: filename,
        });
        setErrorsize({});
      } else {
        setErrorsize({ [name]: true });
      }
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };

  return (
    <div>
      <LoaderComponent open={isActive} />
      <div className="d-flex align-items-center justify-content-between head-container">
        <div className="faq_banner registration-banner d-flex">
          <a href="/">Home&nbsp;/</a>
          <a href="/department/inovation/home"> Innovation&nbsp;/</a>
          <a className="current" href="department/inovation/registration">
            Registration
          </a>
        </div>
        <div className=" mandatory-field d-flex justify-content-end mt-3">
          <p className="">
            <span>
              <span style={{ color: "red" }}>* </span> Mandatory Fields
            </span>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <h4 className="formtitle">Business Growth Program</h4>
      </div>
      <div className="auto-container form">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name of Entity"
            required
            {...props}
            name="entity_name"
            value={formdata.entity_name ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Name of the Promoter"
            required
            {...props}
            name="promotor_name"
            value={formdata.promotor_name ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Mobile No."
            type="number"
            required
            {...props}
            name="mobile"
            minLength={10}
            maxLength={10}
            value={formdata.mobile ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
         
          <TextField
            label="Industry"
            required
            {...props}
            name="industry"
            value={formdata.industry ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Sectors"
            required
            {...props}
            name="sectors"
            value={formdata.sectors ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />

          <TextField
            label="Corporate Identity Number or Udyoga Mitra Number"
            type="text"
            required
            {...props}
            name="cin"
            value={formdata.cin ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <SelectBox
            label="District"
            required
            options={districts}
            value={formdata.districts}
            selectionMessage="Select a District"
            name="districts"
            handleChange={handleChange}
          />

          <br />
          
          <TextField
            label="City"
            required
            {...props}
            name="city"
            value={formdata.city ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Address"
            type="textarea"
            required
            {...props}
            name="address"
            value={formdata.address ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="PAN of the Company in the case of Private Limited, LLP or OPC
            Company. PAN number of the promoter in the case of Proprietorship
            Firm"
            required
            {...props}
            name="pan"
            value={formdata.pan ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
         
          <TextField
            label="Brief Background of all the promoters (Should be in Less than 400
              words. It should have promoter wise educational qualification ,
              work experience and details of previous organizations worked with)"
            type="textarea"
            required
            {...props}
            name="promoter_description"
            value={formdata.promoter_description ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Number of Employees currently working in the organization"
            required
            {...props}
            type="number"
            name="no_of_employees"
            value={formdata.no_of_employees ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />

          <TextField
            label="What is the product that your company is manufacturing ?"
            required
            {...props}
            name="product"
            value={formdata.product ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="How big is the market opportunity ? Share the details of the
            market Size"
            type="textarea"
            required
            {...props}
            name="market_size"
            value={formdata.market_size ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Why are you manufacturing this product ?"
            required
            {...props}
            name="reason_of_manufactoring"
            value={formdata.reason_of_manufactoring ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label=" Who are your key competitors?"
            required
            {...props}
            name="competetors"
            value={formdata.competetors ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label=" Who are your target customers?"
            required
            {...props}
            name="target_customers"
            value={formdata.target_customers ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label=" How is your product different from your competitors ?"
            required
            {...props}
            name="product_difference"
            value={formdata.product_difference ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label=" What is the innovation that you are planning to bring or already
            brought in this product ?"
            required
            name="product_innovations"
            value={formdata.product_innovations ?? ""}
            onChange={handleTextFieldChange}
            {...props}
          />
          <br />
          <TextField
            label="  How are you selling this product ? "
            required
            {...props}
            name="selling_tips"
            value={formdata.selling_tips ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="  How many customers do you have ? "
            required
            {...props}
            type="number"
            name="customers_count"
            value={formdata.customers_count ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="  Total Revenue earned till date ( In lakhs)"
            required
            type="number"
            {...props}
            name="revenue_earned_till"
            value={formdata.revenue_earned_till ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label=" Revenue earned in the financial year 2020-2021(In Lakhs)"
            name="revenue_earned_financial_year"
            value={formdata.revenue_earned_financial_year ?? ""}
            onChange={handleTextFieldChange}
            type="number"
            required
            {...props}
          />
          <br />
          <TextField
            label=" How much loan have you raised from the bank or other lending
            Institutions ? (In Lakh) "
            name="loan_raised"
            value={formdata.loan_raised ?? ""}
            onChange={handleTextFieldChange}
            type="number"
            required
            {...props}
          />
          <br />
          <TextField
            label=" How much money have you invested so far in the company? ( In
              Lakhs) "
            required
            name="money_invested"
            value={formdata.money_invested ?? ""}
            type="number"
            onChange={handleTextFieldChange}
            {...props}
          />
          <br />
          <TextField
            label=" What are the supports required from KIED ? "
            required
            {...props}
            name="support_required"
            value={formdata.support_required ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label=" How did you hear about this Program ? "
            required
            {...props}
            name="how_you_heard"
            value={formdata.how_you_heard ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <FileUpload
            label=" Please upload the detailed business plan  (Upload .pdf, .docx or .doc file, File size should be less than 2 mb) "
            required
            {...props}
            accept=".pdf, .docx, .doc"
            name="business_plan_file"
            type="file"
            // filename={formdata.business_plan_file_name}
            // value={formdata.business_plan_file ?? null}
            onChange={fileUpload}
          />
          {error.business_plan_file && (
            <p id="business_plan_file" className="ml-3 error">
              Unsupported file type! Please select .doc or .pdf file
            </p>
          )}
          {errorsize.business_plan_file && (
            <p id="business_plan_file" className="ml-3 error">
              File is too big!. Please upload a file with size less than 2 mb.
            </p>
          )}
          <br />
          <FileUpload
            label="Please upload the incorporation certificate or Udyoga Mitra Number (Upload .pdf, .docx or .doc file, File size should be less than 2 mb)"
            required
            {...props}
            accept=".pdf, .docx, .doc"
            name="incorporation_file"
            type="file"
            // filename={formdata.incorporation_file_name}
            // value={formdata.incorporation_file ?? null}
            onChange={fileUpload}
          />
          {error.incorporation_file && (
            <p id="certupload" className="ml-3 error">
              Unsupported file type! Please select .doc, .pdf, .jpeg or .jpg
              file
            </p>
          )}
          {errorsize.incorporation_file && (
            <p id="certupload" className="ml-3 error">
              File is too big!. Please upload a file with size less than 2 mb.
            </p>
          )}
          <br />
          <FileUpload
            label="Please upload the audited financial statemet for FY 2020-21 (Upload .pdf or .docx  file, File size should be less than 2 mb)"
            required
            {...props}
            accept=".pdf, .docx"
            name="finacial_file"
            type="file"
            
            onChange={fileUpload}
          />
          {error.finacial_file && (
            <p id="auditupload" className="ml-3 error">
              Unsupported file type! Please select a .pdf file
            </p>
          )}
          {errorsize.finacial_file && (
            <p id="auditupload" className="ml-3 error">
              File is too big!. Please upload a file with size less than 2 mb.
            </p>
          )}
          <br />
          <FileUpload
            label="Last Financial year GST Filing (Upload .pdf or .docx  file, File size should be less than 2 mb)"
            required
            {...props}
            accept=".pdf, .docx"
            name="gst_file"
            type="file"
           
            onChange={fileUpload}
          />
          {error.gst_file && (
            <p id="gstupload" className="ml-3 error">
              Unsupported file type! Please select a .pdf or .docx file
            </p>
          )}
          {errorsize.gst_file && (
            <p id="gstupload" className="ml-3 error">
              File is too big!. Please upload a file with size less than 2 mb.
            </p>
          )}
          <br />
          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleregister()}
              primary
              rounded
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Submit
                </div>
              }
            />
          </div>
          <br />
        </form>
      </div>
    </div>
  );
}

export default RegistrationForms;

import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import ProgramPartner from "../../../../components/program.partners/program.partners";
import { useNavigate, useParams } from "react-router-dom";


function NewProgramSupportingPartners(props) {
  const history = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"))
  const userRole = user?.role

  const param = useParams()
  const handleregister = () => {
 
    history(`/edcform`);
   
  }

  return (
    <div className="">
      <div className="auto-container pb-5 pt-5 ">
        <div className="d-flex justify-content-center align-items-center ">
          <div className="">
            <h2 className="inovation_headings m-1 ">Register Now</h2>
          </div>
        </div>
        
        <div>
          <div className="d-flex justify-content-center mt-5 mb-3">
            <KiedButton
              onClick={() => handleregister()}
              content={
                <>
                  <div className="d-flex p-1">
                    <div className="d-flex align-items-center">
                    
                        Click Here To Apply
                     
                    </div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </div>
        </div>
        {/* } */}
      </div>
    </div>
  );
}

export default NewProgramSupportingPartners;

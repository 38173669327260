import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./VideoCard.css";
import { useNavigate, useParams } from "react-router-dom";
import { VideoDetails } from "../../pages/kied_videos/videoData";
import { useState, useEffect } from "react";
import YouTube from "react-youtube";

function VideoCard(props) {
  const navigate = useNavigate();

  const onPlayerReady = (event) => {
   
    event.target.pauseVideo();
  };
  const opts = {
    height: "420",
    width: "100%",
    playerVars: {
      autoplay: false,
      loop: true,
      object: "fit",
    },
  };
  let url = [];
  url = props.data.video.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)[2];
  

  return (
    <div className=" news-block px-3 h-100 w-100  ">
      <div className="inner-box  h-100 ">
        <div className="image">
          <YouTube videoId={url} onReady={onPlayerReady} opts={opts} />
        </div>

        <div className="lower-content d-flex justify-content-center">
          <h3>{props.data.heading}</h3>
        </div>
      </div>
    </div>
  );
}

export default VideoCard;

import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./news.card.style.css";
import { useNavigate, useParams } from "react-router-dom";
import { newsData } from "../../pages/home/sections/news/newsData";
import { useState, useEffect } from "react";
import parse from "html-react-parser"

function NewsCard(props) {
  const navigate = useNavigate();
 
  return (
    <div className=" news-block px-3 h-100 w-100 ">
      <div className="inner-box wow fadeInUp animated h-100">
        <div className="image">
          <a
            onClick={(e) =>
              navigate(
                props.data.viewmorelink
                  ? props.data.viewmorelink
                  : `/news/${props.data.id}`
                
              )
            }
          >
            <KiedImages className=" news_card_image" src={props.data.photo} />
          </a>
        </div>

        <div className="lower-content">
          <div className="time">On {props.data.date}</div>
          <h3>
            <a
              onClick={(e) =>
                navigate(
                  props.data.viewmorelink
                    ? props.data.viewmorelink
                    : `/homeNews-detail/${props.data.id}`
                )
              }
            >
              {props.data.title}
            </a>
          </h3>
          <div className="text">{props.data.designation && parse(props.data.designation) }</div>
          <div className="clearfix">
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;

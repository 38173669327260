import React from "react";
import Banner from "../../components/banner/banner";
import TenderTable from "./sections/tender-table/tender.table.section";
import "./tenders.page.styles.css";
import { getTenders } from "../../apis/publicApis";
import { useState, useEffect } from "react";

function TendersPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getTenders()
      .then((res) => {
        let prdata = [];
        res?.data?.forEach((item) => {
       
          let prItem = {
            title: item?.description,
            file: item?.file,
          };
          prdata.push(prItem);
        });
        setData(prdata);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <Banner title="Tenders" image="academia-banner" />

      <TenderTable data={data} />
    </div>
  );
}

export default TendersPage;

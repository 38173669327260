import React, { useState } from "react";
import InovationText from "./sections/text/inovation.text";
import InovationEvents from "./sections/Events/inovation.events";
import InovationPrograms from "./sections/Programs/inovation.programs";
import InovationSchemes from "./sections/Schemes/inovation.schemes";
import InovationBanner from "./sections/banner/inovations.banner";
import "./sections/inovation.css";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import {
  getEventBydeptId,
  getProgramBydeptId,
  getSchemeBydeptId,
  getMenu,
} from "../../apis/publicApis";
function Inovation() {
  let navParam = useParams();
  const inovationPrograms = useRef();
  const inovationSchemes = useRef();
  const inovationEvents = useRef();

  const { dept } = useParams();

  const [scheme, setScheme] = useState([]);
  const [events, setEvents] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [department, setDepartment] = useState({});

  const getDeptData = (dept) => {
    getMenu()
      .then((res) => {
        
        let departmentData = res.data.filter((item) => item.id == dept) || [];
        setDepartment(departmentData[0]);
      })
      .catch((err) => {
    
      });
  };
  const getSchemeData = (dept) => {
    getSchemeBydeptId(dept)
      .then((res) => {
     
        setScheme(res.data);
      })
      .catch((err) => {
      
      });
  };
  const getProgData = (dept) => {
    getProgramBydeptId(dept)
      .then((res) => {
        
        setPrograms(res.data);
      })
      .catch((err) => {
        
      });
  };
  const getEventData = (dept) => {
    getEventBydeptId(dept)
      .then((res) => {
       
        setEvents(res.data);
      })
      .catch((err) => {
        
      });
  };
  useEffect(() => {
    getDeptData(dept);
    getSchemeData(dept);
    getProgData(dept);
    getEventData(dept);
  }, [dept]);

  useEffect(() => {
    switch (navParam.id) {
      case "inovationPrograms":
        inovationPrograms.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "inovationSchemes":
        inovationSchemes.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "inovationEvents":
        inovationEvents.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth", 
        });
    }
  }, [navParam.id]);

  return (
    <div className="">
      <InovationBanner data={department} />
      <InovationText data={department} />
      <div ref={inovationSchemes}></div>
      {scheme && <InovationSchemes data={scheme} />}
      <div ref={inovationEvents}></div>
      <InovationEvents data={events} />
      <div ref={inovationPrograms}> </div>
      <div className="greyBackground">
        <InovationPrograms data={programs} />
      </div>
    </div>
  );
}

export default Inovation;

import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";

function InovationBanner(props) {

  return (
    <div
      id="Banner_outer"
      className="banner_image banner_big d-flex justify-content-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between align-items-center"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-end"
      
        >
          <h2 className="text_banner more_link" style={{ color: "#fff" }}>
          
            {props.data.name}
          </h2>
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block"
         
        >
          <KiedImages
            className="inovation_banner_image d-none d-md-block"
            image="inovation-banner"
          />
        </div>
      </div>
    </div>
  );
}

export default InovationBanner;

import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import Masonry from "react-responsive-masonry";

function NewsDetailDescription(props) {

  return (
    <div>
    
      <div className="auto-container">
        <div className="d-flex justify-content-center mt-5">
          <h1 className="dedicated-news-heading mb-2">{props.data.title}</h1>
        </div>
        <div className=" mt-3 mb-2 pb-2">
          <div className="row d-flex justify-content-center">
            <div className="col-md-4 col-sm-12 mb-2">
              <KiedImages className="w-100" image={props.data.image} />
            </div>
            <div className="col-md-7 col-s-12 d-flex flex-column align-items-start justify-content-center">
              <div className="Schemes_heading_description mt-2">
                {props.data.description}
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-5 mb-5 mx-3">
          {props.data.maindescription && props.data.maindescription !== "" && (
            <div>
              <div className="description_heading_news ">
                {props.data.maindescriptionheading}
              </div>
              <div className="Schemes_heading_description mb-3">
                {props.data.maindescription}
              </div>
              <div className="description_heading_news">
                {props.data.maindescription2heading}
              </div>
              <div className="Schemes_heading_description mb-3">
                {props.data.maindescription2}
              </div>
            </div>
          )}
          <div className=" mt-4 d-flex flex-column align-items-start justify-content-center mb-3">
            <div className="Schemes_heading_description">
              {props.data.duration && (
                <div className="m-1">
                  <b>Duration :</b>
                  {props.data.duration}
                </div>
              )}
              {props.data.category && (
                <div className="m-1">
                  <b>Category of Participants:</b>
                  {props.data.category}
                </div>
              )}
              {props.data.noofparticipants && (
                <div className="m-1">
                  <b>Number of Participants:</b>
                  {props.data.noofparticipants}
                </div>
              )}
              {props.data.venue && (
                <div className="m-1">
                  <b>Venue:</b>
                  {props.data.venue}
                </div>
              )}
              {props.data.date && (
                <div className="m-1">
                  <b>Date:</b>
                  {props.data.date}
                </div>
              )}
            </div>
          </div>

          {props.data.listitem && props.data.listitem !== "" && (
            <div className="mt-3 mb-3">
              <div className="description_heading_news">
                {props.data.listheading}
              </div>
              <ul className="Schemes_heading_description">
                {props.data.listitem.map((list, i) => (
                  <li className="ml-4">{list}</li>
                ))}
              </ul>
            </div>
          )}
          {props.data.image && props.data.image !== "" && (
            <div className="pt-3 mb-5 ">
              <div className="d-flex justify-content-center  gallery_heading_news">
                Gallery
              </div>
              <Masonry columnsCount={2} gutter="10px">
                {props.data.images.map((image, i) => (
                  <KiedImages
                    image={image.image}
                    style={{ width: "100%", display: "block" }}
                  />
                ))}
              </Masonry>
            </div>
          )}
          <div className=" Schemes_heading_description"></div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetailDescription;

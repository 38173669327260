import React from "react";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedImages from "../../../../assets/images/kied.images.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import { logedUser } from "../../../../apis/auth.apis";
import { useState } from "react";
import Swal from "sweetalert2";



function EDCBanner(props) {
  let isAuthenticated = props.state.isAuthenticated;
  let userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const history = useNavigate();


  const handleregister = () => {
    if (isAuthenticated) {
      if (userData?.role === 3 || userData?.role === 5) {
        if (userData?.is_profile_complete === 1) {
          if (userData?.status === 1) {
            sessionStorage.setItem(
              "next_path",
              "/department/inovation/edcregistration"
            );
            history("/department/inovation/edcregistration");
          } else {
            Swal.fire({
              icon: "warning",
              title: "Warning",
              text: "Your profile is not yet accepted by the admin. You will receive an email confirmation once the admin accepts your profile.",
              
              confirmButtonColor: "#ffa93c",
              confirmButtonText: "Ok"
            }).then((result) => {
              history("/");
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Warning",
            text: "You have to complete your profile to access this section",
            confirmButtonColor: "#ffa93c",
            confirmButtonText: "Ok"
          }).then((result) => {
            history("/dashboard");
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "No Access",
          text: "Sorry!, Only Schools and Colleges & Research Institutions and Universities have access to this section.",
          confirmButtonColor: "#ffa93c",
          confirmButtonText: "Ok"
        }).then((result) => {
          history("/");
        });
      }
    } else {
      history("/user-management");
    }
  };
  // const handleregister = () => {

  //   sessionStorage.setItem(
  //     "next_path",
  //     "/department/inovation/edcregistration"
  //   );
  //   history("/department/inovation/edcregistration");
  // };
  return (
    <>
      <div
        id="Banner_outer"
        className="banner_image banner_big d-flex justify-content-center align-items-end"
      >
        <div
          id="Banner_inner"
          className="d-flex  container bannerContainer  head-container justify-content-between"
        >
          <div
            id="Banner_text"
            className="d-flex flex-column justify-content-end"
            // data-aos="fade-right"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out"
          >
            <h2 className="text_banner more_link">
              Entrepreneurship Development Club
            </h2>
            {/* <div className="more_link">
              <div className=" d-flex ">
                <span className="prog-banner">
                  <b>170+ :</b> New ED Clubs , <b> 60+ :</b> New Students
                </span>
              </div>
            </div> */}
            {/* <div className="more_link">
              <KiedButton
                // disabled={
                //   props.state.isAuthenticated
                //     ? userData?.role == 3 || userData?.role == 5
                //       ? false
                //       : true
                //     : false
                // }
                onClick={() => handleregister()}
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center m-2">
                        Online Registration of ED Clubs
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />
            </div> */}
          </div>
          <div
            id="Banner_image"
            className="aboutpage_images  d-none d-md-block
          "
            // data-aos="fade-left"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out"
          >
            <KiedImages
              className="program-banner-image d-none d-md-block"
              image="academia-banner"
            />
          </div>
        </div>
      </div>
      <div className="banner-stats-container d-flex justify-content-around w-100 ">
        <div className="d-flex flex-column banner-stats align-items-center justify-content-center  w-100">
          <span className="stats-value">120+</span>
          <span className="stats-heading">Programs</span>
        </div>
        <div className="d-flex flex-column banner-stats align-items-center justify-content-center  w-100">
          <span className="stats-value">26000+</span>
          <span className="stats-heading">Man Days</span>
        </div>
        <div className="d-flex flex-column banner-stats align-items-center justify-content-center  w-100">
          <span className="stats-value">9000+</span>
          <span className="stats-heading">Participants</span>
        </div>
        <div className="d-flex flex-column banner-stats-left align-items-center justify-content-center  w-100">
          <span className="stats-value">6+</span>
          <span className="stats-heading">Initiatives</span>
        </div>
      </div>
    </>
  );
}

export default EDCBanner;

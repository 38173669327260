import React from "react";

function TextField(props) {
  const handleChange = (e, type = "text") => {
    
    let data = null;

    let value = e.target.value;
    let name = e.target.name;
    var numbersonly = new RegExp("^[0-9]*$");

    if (type === "number") {
      if (numbersonly.test(value)) {
        data = e.target.value;
      }
    } else {
      data = e.target.value;
    }
    props.onChange(data, name);
  };
  return (
    <>
      {props.label && (
        <label className="formstyle">
          <span>
            {props.label}{" "}
            {props.required && <span style={{ color: "red" }}>*</span>}
          </span>
        </label>
      )}
      <label className={props.hidelabelStyle ? "w-100" : "formstyle"}>
        {props.type === "textarea" ? (
          <textarea
            className={props.className ?? "inputbox"}
            name={props.name}
            value={props.value ?? ""}
            onChange={(e) => handleChange(e, props.type)}
            id="address"
            placeholder={props.placeholder ?? ""}
            maxLength={props.maxLength}
            minLength={props.minLength}
            required={props.required}
            disabled = {props.disabled}
          />
        ) : (
          <input
            className={props.className ?? "inputbox "}
            minLength={props.minLength ?? undefined}
            maxLength={props.maxLength ?? undefined}
            placeholder={props.placeholder ?? ""}
            disabled = {props.disabled}
            name={props.name}
            value={props.value ?? ""}
            required={props.required}
            onChange={(e) => handleChange(e, props.type)}
            type={
              props.type === "email"
                ? "email"
                : props.type === "date"
                ? "date"
                : "text"
            }
            id="promoter_name"
          />
        )}
      </label>
    </>
  );
}

export default TextField;

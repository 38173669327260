import React from "react";
import Banner from "../../components/banner/banner";
import OurPeople from "./sections/Our_people/our_people";
import OurTeamMembers from "./sections/Our_team_members/our_team_members";
import { getMember } from "../../apis/publicApis";
import { useState, useEffect } from "react";

function TeamPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getMember()
      .then((res) => {
        let prdata = [];
        res?.data?.forEach((item) => {
       
          let prItem = {
            name: item?.name,
            image: item?.photo,
            designation: item?.designation,
          };
          prdata.push(prItem);
        });
        setData(prdata);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <Banner title="Governing Council Members" image="team-banner" />
      
      <OurTeamMembers data={data} />
    </div>
  );
}

export default TeamPage;

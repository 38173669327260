import React, { useEffect, useState } from "react";
// import Payment from './sections/success.card/success.card'
import PaymentSuccessCard from "./sections/success.card/success.card";
import { getPaymentStatus } from "../../apis/publicApis";
import PaymentFailedCard from "./sections/error.card.jsx/failure.card";
import { useParams, useSearchParams } from "react-router-dom";

function PaymentSuccessPage() {
  const [status, setStatus] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [order, setOrder] = useState({});

  const { orderid, bid } = useParams();
 
  const order_id = sessionStorage.getItem("order_id");
  const tid = sessionStorage.getItem("tid");
  const [searchParams] = useSearchParams();
  const text = searchParams.get("bid");
 
  
  const getStatus = () => {
    let payload = {
      order_id: orderid,
      bid: bid,
    };
    
    getPaymentStatus(payload).then((res) => {
     
      setStatus(res?.response2?.Order_Status_Result?.order_bank_response);
      setCode(res?.response2?.Order_Status_Result?.order_status);
      setError(res?.response2?.Order_Status_Result?.order_bank_response);
      setData(res?.response2?.Order_Status_Result);
      setOrder(res?.order_id);

     
      setTimeout(() => {
        setShow(true);
      }, 100);
    });
  };
  useEffect(() => {
    getStatus();
  }, []);

  

  return (
    <>
      {show && (
        <div>
          {code === "Successful" ? (
            <PaymentSuccessCard message={data} order={order} />
          ) : code === "Aborted" || code === "Unsuccessful" ? (
            <PaymentFailedCard message={code} error={error} />
          ) : (
            <PaymentFailedCard message={status} />
          )}
        </div>
      )}
    </>
  );
}

export default PaymentSuccessPage;

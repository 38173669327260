import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import KiedButton from "../buttons/button/button.component";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function ProgramListing(props) {
  const history = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.role;
  const param = useParams();

  const handleRegister = (id, role = "") => {
    if (role.includes(userRole)) {
      history(`/department/inovation/registration/${id}`);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "This program is not meant for your user type",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };

  const width = useScreenBreakPoint();
  const navigate = useNavigate();

  const navToDetails = (id) => {
    if (props.type === "EventList") {
      navigate(`/events/${id}`);
    } else if (props.type === "ProgramList") {
      navigate(`/program/${id}`);
    } else {
      navigate(`/schemes/${id}`);
    }
  };

  const isDateExpired = () => {
    const currentDate = new Date();
    const eventDate = new Date(props.data.date);
    return eventDate < currentDate;
  };

  return (
    <div
      className="events-listing-card w-100 mb-2"
      style={{ backgroundColor: "#fff" }}
    >
      <table className="table table-bordered">
        <thead>
          <tr style={{ backgroundColor: "#000 !important" }}>
            <th
              style={{
                backgroundColor: "#f5b296",
                color: "#262323",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Program
            </th>
            <th
              style={{
                backgroundColor: "#f5b296",
                color: "#262323",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Location
            </th>
            {/* <th style={{ backgroundColor: "#f5b296", color: "#262323", fontSize: "18px", fontWeight: "600" }}>Owner</th> */}
            <th
              style={{
                backgroundColor: "#f5b296",
                color: "#262323",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Participants
            </th>
            <th
              style={{
                backgroundColor: "#f5b296",
                color: "#262323",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Date
            </th>
            <th
              style={{
                backgroundColor: "#f5b296",
                color: "#262323",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Status
            </th>
            <th
              style={{
                backgroundColor: "#f5b296",
                color: "#262323",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "150px", padding: "5px" }}>
              <KiedImages
                className="listing-image"
                link={props.data.listingImage}
                style={{ width: "100px", height: "50px" }}
              />
              <br />
              <span
                onClick={() => navToDetails(props?.data?.id)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                {props.data.title}
              </span>
            </td>
            <td style={{ width: "150px" }}>{props.data.location || "--"}</td>
            {/* <td style={{ width: "150px" }}>{props.data.scheme_owner || "--"}</td> */}
            <td
              style={{
                width: "150px",
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
            >
              {/* {props.data.cardDescription
                ? parse(props.data.cardDescription)
                : "--"} */}

              {props?.data?.role_id.map((item, i) =>
                item === 2 ? (
                  <div className="p-1">Aspiring Enterpreneur</div>
                ) : item === 3 ? (
                  <div className="p-1">Schools and Colleges</div>
                ) : item === 4 ? (
                  <div className="p-1"> Existing Entrepreneur</div>
                ) : item === 5 ? (
                  <div className="p-1">
                    {" "}
                    Research Institutions and Universities
                  </div>
                ) : item === 6 ? (
                  <div className="p-1"> Industry Associations / Enablers</div>
                ) : item === 7 ? (
                  <div className="p-1"> Expert / Mentors</div>
                ) : item === 8 ? (
                  <div className="p-1"> ED Club</div>
                ) : item === 9 ? (
                  <div className="p-1">Student</div>
                ) : item === 10 ? (
                  <div className="p-1">Guest</div>
                ) : (
                  "--"
                )
              )}
            </td>
            <td style={{ width: "150px" }}>{props.data.date}</td>
            <td style={{ width: "150px" }}>
              {props.data.status ? (
                <span
                  style={{
                    padding: "5px 10px",
                    color: "#000",
                    borderRadius: "5px",
                  }}
                >
                  {props.data.status}
                </span>
              ) : (
                "--"
              )}
            </td>
            <td style={{ width: "150px" }}>
              {isDateExpired() ? (
                <span
                  style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}
                >
                  Registration Closed
                </span>
              ) : (
                <KiedButton
                  primary
                  rounded
                  className="readmore-btn"
                  onClick={() =>
                    handleRegister(props?.data?.id, props?.data?.role)
                  }
                  content="Apply Now"
                  disabled={isDateExpired()}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ProgramListing;

import React, { forwardRef, useImperativeHandle } from "react";
import Swal from "sweetalert2";
import RadioButtonDash from "../../../../components/radiobutton-dash/radiobutton";
import SelectBoxDash from "../../../../components/selectbox-dash/SelectBox.component";
import TextFieldDash from "../../../../components/textfield-dash/TextField.component";
import {
  KiedCarriers,
  castCategory,
  gender,
  radiooption,
  businessStage,
  businessduration,
  mode,
} from "../../../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../../../registration/registrationform.data";
import KiedButton from "../../../../components/buttons/button/button.component";
import { toast } from "react-toastify";
import FileUploadDash from "../../../../components/file.upload-dash/fileupload.component";

const SchoolsRegistrationForm = (
  {
    handleChange,
    handleTextFieldChange,
    formdata,
    error,
    setError,
    fielderror,
    setSubmitted,
    handleSubmit,
    setCompleted,
    setFieldError,
    userData,
    userid,
    accountname,
    errorSize,
    accountno,
    whatsapp_no,
    userrole,
    schoolCollege,
    setProfiledit,
    fetchprofile,
    createProfile,
    validateField,
    fileUpload,
    submitted,
  },
  ref
) => {
  const requiredFields = [
    "school_name",
    "address",
    "head_name",
    "head_designation",
    "head_email",
    "head_contact_no",
    "name_spc",
    "designation_spc",
    "email_spc",
    "contact_no_spc",
    "institution_type",
    "area_expertise",
    "auth_letter",
  ];
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      dataSetter();
    },
  }));

  const validate = () => {
    let errorField = {};
   
    var emailsonly = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
    requiredFields.forEach((item) => {
      if (
        formdata[item] === null ||
        formdata[item] === undefined ||
        formdata[item] === ""
      ) {
        errorField[item] = "Please fill in the details";
        setSubmitted(false);
      }
     

      if (item == "head_email" && !emailsonly.test(formdata[item])) {
        errorField[item] = "Please fill a correct email";
        setSubmitted(false);
       
      }
      if (item == "email_spc" && !emailsonly.test(formdata["email_spc"])) {
        errorField[item] = "Please fill a correct email";
        setSubmitted(false);
      }
    });
    return errorField;
  };
  const dataSetter = () => {
    setSubmitted(true);

    let payload = new FormData();

   
    if (formdata.name && formdata.name !== "") {
      payload.append("name", formdata.name);
    } else {
      payload.append("name", accountname);
    }

    if (formdata.mobile && formdata.mobile !== "") {
      payload.append("mobile", formdata.mobile);
    } else {
      payload.append("mobile", accountno);
    }
    if (formdata.whatsapp_no && formdata.whatsapp_no !== "") {
      payload.append("whatsapp_no", formdata.whatsapp_no);
    } else {
      payload.append("whatsapp_no", whatsapp_no);
    }

    // payload.append("mobile", formdata.mobile);
    // payload.append("whatsapp_no", formdata.whatsapp_no);
    payload.append("user_id", userid);
    payload.append("role", userrole);

    if (formdata.school_name !== "") {
      payload.append("school_name", formdata.school_name);
    }
    if (formdata.address !== "" && formdata.address) {
      payload.append("address", formdata.address);
    }
    if (formdata.head_name !== "" && formdata.head_name) {
      payload.append("head_name", formdata.head_name);
    }
    if (formdata.head_designation !== "" && formdata.head_designation) {
      payload.append("head_designation", formdata.head_designation);
    }
    if (formdata.head_email !== "" && formdata.head_email) {
      payload.append("head_email", formdata.head_email);
    }
    if (formdata.head_contact_no !== "" && formdata.head_contact_no) {
      payload.append("head_contact_no", formdata.head_contact_no);
    }
    if (formdata.name_spc !== "" && formdata.name_spc) {
      payload.append("name_spc", formdata.name_spc);
    }
    if (formdata.designation_spc !== "" && formdata.designation_spc) {
      payload.append("designation_spc", formdata.designation_spc);
    }
    if (formdata.email_spc !== "" && formdata.email_spc) {
      payload.append("email_spc", formdata.email_spc);
    }
    if (formdata.contact_no_spc !== "" && formdata.contact_no_spc) {
      payload.append("contact_no_spc", formdata.contact_no_spc);
    }
    if (formdata.institution_type !== "" && formdata.institution_type) {
      payload.append("institution_type", formdata.institution_type);
    }
    if (formdata.area_expertise !== "" && formdata.area_expertise) {
      payload.append("area_expertise", formdata.area_expertise);
    }
    if (formdata.auth_letter !== "" && formdata.auth_letter) {
      payload.append("auth_letter", formdata.auth_letter);
    }

    payload.append("user_id", userid);
    const errorField = validate();
    
    
    if (Object.keys(errorField).length === 0) {
      handleSubmit(payload);
    } else {
      setFieldError(errorField);
      setSubmitted(false);
      setCompleted(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields correctly (Mandatory fields are marked in red)",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <div>
      <div>
        <div className="">
          <TextFieldDash
            label="School/College Name"
            required
            error={fielderror.school_name}
            name="school_name"
            value={formdata.school_name ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextFieldDash
            label="Address"
            required
            error={fielderror.address}
            name="address"
            type="textarea"
            value={formdata.address ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextFieldDash
            label="Name of Head of the Institution"
            required
            error={fielderror.head_name}
            name="head_name"
            value={formdata.head_name ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextFieldDash
            label="Designation of Head of the Institution"
            required
            error={fielderror.head_designation}
            name="head_designation"
            value={formdata.head_designation ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextFieldDash
            label="E-mail of Head of the Institution"
            required
            error={fielderror.head_email}
            type="email"
            name="head_email"
            value={formdata.head_email ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />

          <TextFieldDash
            label="Contact Number of Head of the Institution"
            required
            error={fielderror.head_contact_no}
            type="phone"
            maxLength={10}
            minLength={10}
            name="head_contact_no"
            value={formdata.head_contact_no ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <hr />
          <br />
          <TextFieldDash
            label="Name of Single Point of Contact at the Institution"
            required
            error={fielderror.name_spc}
            name="name_spc"
            value={formdata.name_spc ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextFieldDash
            label="Designation of Single Point of Contact"
            required
            error={fielderror.designation_spc}
            name="designation_spc"
            value={formdata.designation_spc ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextFieldDash
            label="E-mail of Single Point of Contact"
            required
            error={fielderror.email_spc}
            type="email"
            name="email_spc"
            value={formdata.email_spc ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextFieldDash
            label="Contact Number of Single Point of Contact"
            required
            error={fielderror.contact_no_spc}
            type="phone"
            maxLength={10}
            minLength={10}
            name="contact_no_spc"
            value={formdata.contact_no_spc ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <SelectBoxDash
            label="Type of Institution"
            required
            error={fielderror.institution_type}
            options={schoolCollege}
            value={formdata.institution_type}
            selectionMessage="Select your Institution Type"
            name="institution_type"
            handleChange={handleChange}
          />
          <br />

          <TextFieldDash
            label="Area of Expertise of the organization"
            required
            error={fielderror.area_expertise}
            name="area_expertise"
            value={formdata.area_expertise ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <FileUploadDash
            label={
              <span>
                Authorization Letter from Head of the Institution{" "}
                <a
                  href={`${process.env.PUBLIC_URL}/files/a_letter.pdf`}
                  target="_blank"
                >
                  Click here to download format of the letter
                </a>
                (.pdf, .docx, .jpg format files are allowed)
              </span>
            }
            required
            error={fielderror.auth_letter}
            accept=".pdf, .docx, .jpg"
            name="auth_letter"
            errorSize={errorSize?.auth_letter}
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />

          {error.proof && (
            <p id="proof" className="ml-3 error">
              Unsupported file type! Please select a .pdf or .docx file
            </p>
          )}
          <br />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(SchoolsRegistrationForm);

import React from "react";
import { useState } from "react";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { Accordion } from "react-bootstrap";
import parse from "html-react-parser";

function NewProgramDetails(props) {
  let width = useScreenBreakPoint();
  let objectivedata = props?.data?.objectives;

  let programdata = props?.data?.program_structure;
  let eligibledata = props?.data?.eligibility;

  {

  }
  {
  
  }
  

  let feesdata = props?.data?.fees;

  const [title, setTitle] = useState("Objectives");
  const [datatype, setDatatype] = useState(objectivedata);

  const handleobjectives = (e) => {
    setTitle("Objectives");
    setDatatype(objectivedata ?? "N/A");
  };
  const handleobjectivesr = (e) => {
    if (title !== "Objectives") setTitle("Objectives");
    else setTitle(null);
   
    setDatatype(objectivedata ?? "N/A");
  };
  const handleEligibility = (e) => {
    setTitle("Eligibility");
    setDatatype(eligibledata ?? "N/A");
  };
  const handleEligibilityr = (e) => {
    if (title !== "Eligibility") setTitle("Eligibility");
    else setTitle(null);
   
    setDatatype(eligibledata ?? "N/A");
  };
  const handleProgram = (e) => {
    setTitle("Program Structure");
    setDatatype(programdata ?? "N/A");
    
  };
  const handleProgramr = (e) => {
    if (title !== "Program Structure") setTitle("Program Structure");
    else setTitle(null);
    
    setDatatype(programdata ?? "N/A");
  };
  const handleFees = (e) => {
    setTitle("Fees");
    setDatatype(feesdata ?? "N/A");
  };
  const handleFeesr = (e) => {
    if (title !== "Fees") setTitle("Fees");
    else setTitle(null);
    
    setDatatype(feesdata ?? "N/A");
  };
  return width === "xs" || width === "sm" ? (
    <div className="auto-container responsiveProgramDetails">
      <Accordion
        defaultActiveKey="0"
        className=""
        style={{ marginTop: "45px" }}
      >
        <Accordion.Item eventKey="0" className="">
          <Accordion.Header
            onClick={() => handleobjectivesr()}
            className={title === "Objectives" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">Objectives</span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={title === "Objectives" ? "arrow-white" : "arrow-right"}
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer ">
              <ul>{datatype || objectivedata}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="">
          <Accordion.Header
            onClick={() => handleEligibilityr()}
            className={title === "Eligibility" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">Eligibility</span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={
                    title === "Eligibility" ? "arrow-white" : "arrow-right"
                  }
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer">
              <ul>{datatype}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="">
          <Accordion.Header
            onClick={() => handleProgramr()}
            className={title === "Program Structure" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">
                Program Structure
              </span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={
                    title === "Program Structure"
                      ? "arrow-white"
                      : "arrow-right"
                  }
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer">
              <ul>{datatype}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="">
          <Accordion.Header
            onClick={() => handleFeesr()}
            className={title === "Fees" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">Fees</span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={title === "Fees" ? "arrow-white" : "arrow-right"}
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer">
              <ul>{datatype}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  ) : (
    <div className="detail-container my-1">
      <div className="auto-container d-flex mt-5 mb-5">
        <div className=" col-4 buttonSection d-flex flex-column justify-content-start">
          
          <div>
            <button
              onClick={() => handleobjectives()}
              className={title === "Objectives" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Objectives</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={
                      title === "Objectives" ? "arrow-white" : "arrow-right"
                    }
                  />
                </span>
              </span>
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-around">
            <button
              onClick={() => handleEligibility()}
              className={title === "Eligibility" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Eligibility</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={
                      title === "Eligibility" ? "arrow-white" : "arrow-right"
                    }
                  />
                </span>
              </span>
            </button>
          </div>
          <div>
            <button
              onClick={() => handleProgram()}
              className={title === "Program Structure" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Program Structure</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={
                      title === "Program Structure"
                        ? "arrow-white"
                        : "arrow-right"
                    }
                  />
                </span>
              </span>
            </button>
          </div>
          <div className="lastButton">
            <button
              onClick={() => handleFees()}
              className={title === "Fees" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Fees</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={title === "Fees" ? "arrow-white" : "arrow-right"}
                  />
                </span>
              </span>
            </button>
          </div>
        </div>
        <div className="col-8 detailSection d-flex justify-content-end align-items-start">
          <div className="col-8">
            <h1 id="changingTitle" className="detail-title ">
              {title}
            </h1>
            {(datatype || objectivedata) &&
            <div className="detail-description">
              
              <ul>{parse(datatype || objectivedata)}</ul>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewProgramDetails;

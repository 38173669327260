import React, { useState } from "react";
import { json, useNavigate } from "react-router-dom";
import KiedImages from "../../../assets/images/kied.images.component";
import KiedButton from "../../../components/buttons/button/button.component";
import { useLangStore ,useLoginState} from "../../../utils/zustand";
function PartnerLogoComponent(props) {
  let isAuthenticated = props.state.isAuthenticated;
  const navigate = useNavigate();
  const {language, setLanguage} = useLangStore()
  const [keyValue, setKeyValue] = useState(1);
  const loginState = useLoginState();
  
  const handleButton = () => {
    if (isAuthenticated) {
      props.logout();
      sessionStorage.clear();
      localStorage.clear();
    } else {
      loginState.onLoginClick();
      navigate("/");
    }
  };
  let loggedUser = localStorage.getItem("user")
  
  let userData = loggedUser
    ? JSON.parse(loggedUser)
    : null;
  let username = userData?.name;
 
  const nameArray = username?.substring(0, 1) || "";
  // let word = nameArray[0];
  // let letterArray = word[0];

  const navigatepage = (e) => {
    navigate("/dashboard");
  };
  const langSet = (val) => {
setLanguage(val)
  }
  return (
    <div id="partners-section" className="">
      <div className="d-flex justify-content-between head-container">
        <div className="">
          <KiedImages
            image="kgov-logo"
            className="header-govt-logo"
            alt="Kerala Government"
          />
          
        </div>

        <div className="d-flex justify-content-end ">
        
          <div className="px-5">
            {/* <a
              href="http://202.88.244.195/graphics/arya/kied/index.html"
              target="_blank"
              rel="noreferrer"
            >
              <KiedImages
                image="ceed-logo"
                className="header-govt-logo"
                alt="Kerala Government"
              />
            </a> */}
          </div>
          
          {props.state.isAuthenticated && (
            <div className="d-flex align-items-center mx-3">
              <div className="profilei" onClick={(e) => navigate("/dashboard")}>
                {nameArray}
              </div>
            </div>
          )}
          <div className="d-flex align-items-center">
            <KiedButton
              primary
              onClick={(e) => handleButton()}
              rounded
              content={
                <div className="d-flex align-items-center">
                  {props.state.isAuthenticated ? "Logout" : "Login"}{" "}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerLogoComponent;

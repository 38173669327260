import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./programs.card.style.css";
import { useNavigate, useParams } from "react-router-dom";
import KiedButton from "../buttons/button/button.component";
import parse from "html-react-parser";
function ProgramsCard(props) {
  const navigate = useNavigate();

  return (
    <div className="row mx-0 card-border w-100">
      <div className="col-lg-4 col-md-4 col-sm-12 p-0 ">
        <div className="program-card-img ">
          <div className="ribbon1">
            <span>{props.data.status}</span>
          </div>
          <div>
            <img src={props?.data?.thumb} className="progrram-image w-100" />
          </div>
        </div>
      </div>
      <div className="col-lg-8 col-md-8 col-sm-12 px-3">
        <div id="inovation_programs_title" className=" pt-4 ">
          <h3 id="" className="programs_title more_link">
            {props.data.name}
          </h3>
        </div>
        <div className="program_card_contents_container">
          <div id="Inovations_programs_venue" className="d-flex p-1">
            <div id="locationLogo">
              <KiedImages className="pr-1 mr-1" image="location-icon" />
            </div>
            <div id="Inovations_programs_venue" className="inovation_color">
              {props.data.location}
            </div>
          </div>
          <div
            id="Inovations_programs_date"
            className="d-flex align-items-center"
          >
            <div id="calenderLogo">
              <KiedImages className="p-1 mr-1" image="calender-icon" />
            </div>
            <span>
              {props.data.date} {props.data.end_date && <>-</>}{" "}
              {props.data.end_date}{" "}
            </span>
          </div>
          <br />
          <div id="Inovations_program_content" className="">
            <span className="p-1 programs_participant">
              Who can participate{" "}
              <KiedImages className="" image="question-icon" />
            </span>
            <p className="mt-2 p-1 inovation_color">
              {props.data.participants}
            </p>
            <div className="p-1 inovation_text">
              {props.data.description ? parse(props.data.description) : ""}
            </div>
          </div>
        </div>

        <div className="mt-4 ml-3 mb-3">
          {props.title !== "INDUSTRY PROBLEM - SOLUTION LINKING" ||
          props.title !== "INTERNSHIP CONNECT" ? (
            <KiedButton
              className="readmore_btn "
              primary
              rounded
              content={
                <a
                  className="mt-4 ml-1 event_readmore"
                  onClick={(e) =>
                    navigate(
                      `${
                        props?.data?.id == 999
                          ? "/programs/enterprise-development/details"
                          : `/program/${props.data.id}`
                      }`
                    )
                  }
                >
                  Learn More
                </a>
              }
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ProgramsCard;

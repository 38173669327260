import React from 'react'

function Expanded() {
    return (
      
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 15L12 9L6 15" stroke="#f8463f" stroke-width="2" />
            </svg>
       
    )
}

export default Expanded
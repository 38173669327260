import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import TextField from "../../components/textfield/TextField.component";
import RadioButton from "../../components/radiobutton/radiobutton";
import SelectBox from "../../components/selectbox/SelectBox.component";
import KiedButton from "../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/file.upload/fileupload.component";
import { useState } from "react";
import { institution } from "../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../registration/registrationform.data";

function IndustryForm() {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleTextFieldChange = (value, name) => {
    setFormdata({ ...formdata, [name]: value });
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
    
  };

  const handleregister = () => {
    navigate("/success");
  };

  const fileUpload = (e) => {
 
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        [name]: value,
        [name + "_name"]: filename,
      });
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };

  return (
    <>
      <SmallBanner title="Industry Associations Data Form" />
      <div>
        <div className="d-flex align-items-center justify-content-between head-container">
          <div className="faq_banner  d-flex">
            <a href="/">Home&nbsp;/</a>

            <a className="current">Registration</a>
          </div>
          <div className=" d-flex justify-content-end mt-3">
            <p className="">
              <span>
                <span style={{ color: "red" }}>* </span> Mandatory Fields
              </span>
            </p>
          </div>
        </div>

        <div className="auto-container form">
          <form>
            <TextField
              label="Institute/ Organization Name"
              required
              name="name"
              value={formdata.name ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Address"
              required
              name="address"
              value={formdata.address ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Name of Head of the Institution"
              required
              name="headname"
              value={formdata.headname ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Designation of Head of the Institution"
              required
              name="designation"
              value={formdata.designation ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="E-mail Address"
              required
              type="email"
              name="email"
              value={formdata.email ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Contact Number of Head of the Institution"
              required
              type="number"
              name="phone"
              value={formdata.phone ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <hr />
            <br />
            <TextField
              label="Name of Single Point of Contact at the Institution"
              required
              name="spcname"
              value={formdata.spcname ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Designation of Single Point of Contact"
              required
              name="spcdesignation"
              value={formdata.spcdesignation ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="E-Mail of Single Point of Contact"
              required
              type="email"
              name="spcemail"
              value={formdata.spcemail ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Contact Number of Single Point of Contact"
              required
              type="number"
              name="spcno"
              value={formdata.spcno ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />

            <TextField
              label="Type of Institution"
              required
              name="instype"
              value={formdata.instype ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />

            <TextField
              label="Area of Expertise of the organization"
              required
              name="areaexp"
              value={formdata.areaexp ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <FileUpload
              label={
                <span>
                  Authorization Letter from Head of the Institution{" "}
                  <a
                    href={`${process.env.PUBLIC_URL}/files/a_letter.pdf`}
                    target="_blank"
                  >
                    Click here to download format of the letter
                  </a>
                  (.pdf, .docx, .jpg format files are allowed)
                </span>
              }
              required
              accept=".pdf, .docx, .jpg"
              name="authproof"
              type="file"
              // filename={formdata.gst_file_name}
              // value={formdata.gst_file ?? null}
              onChange={fileUpload}
            />

            {error.proof && (
              <p id="proof" className="ml-3 error">
                Unsupported file type! Please select a .pdf or .docx file
              </p>
            )}
            <br />
          </form>
          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleregister()}
              primary
              rounded
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Submit
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default IndustryForm;

import React from "react";
import SmallBanner from "../../../components/small.banner/small.banner";
import "./news.detail.page.styles.css";
import NewsDetailDescription from "./sections/news.detail.description";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { newsData } from "../../home/sections/news/newsData";

function NewsDetailPage() {
  const param = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    let selected = newsData.filter((item) => {
   
      return item.id == param.id;
    });
    setData(selected[0]);
  }, [param.id]);
  
  return (
    <div>
      <SmallBanner />
      <NewsDetailDescription data={data} />
    </div>
  );
}

export default NewsDetailPage;

import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import SchemeCard from "../../../../components/scheme.cards/scheme.card";
import { EDCData } from "../../edc.page.data";

function EDCSchemes() {
  return (
    <div
      className=""
      style={{ backgroundColor: "#EFEFEF", paddingBottom: "68px" }}
    >
      <div className="auto-container pb-5 pt-5">
        <div id="" className="d-flex justify-content-center align-items-center">
          <div className="">
            <h2 className="inovation_headings m-1 ">Schemes</h2>
          </div>
        </div>
        <div className="mt-5 ">
          <div className="  d-flex justify-content-around  col-sm-10 w-100">
            <div className="d-flex justify-content-center align-items-center  flex-wrap  scheme_image col-md-10">
              {EDCData.map((card, i) => {
                return <SchemeCard data={card} key={i} index={i} />;
              })}
            </div>

            <div className="w-100 scheme_card_image d-none d-md-block col-lg-5 col-md-6 d-flex h-100 align-items-center">
              <KiedImages
                className="w-100 scheme_image_border"
                image="schemes-dec1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EDCSchemes;

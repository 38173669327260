import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function InovationText(props) {
  const width = useScreenBreakPoint();
  return (
    <div
      className={`row auto-container d-flex ${
        width === "xs" || width === "sm"
          ? "flex-column align-items-center mt-4"
          : " align-items-center my-5 pr-3 pl-4"
      }`}
    >
      <div className="col-lg-6 col-md-6 col-sm-12 p-0  w-100">
        {/* <KiedImages className="w-100  hero-img" image="innovation-new" /> */}
        <img src={props.data.image} alt="" className="w-100"/>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 p-0 ">
        <div className=" inovation_text d-flex p-4">
          {/* <p className="py-5">
            The Centre for Innovation provides support to unique and innovative
            small business units with a range of services aimed at helping these
            businesses succeed. These services include incubation services,
            business acceleration services, business clinics, mentor support,
            and boot camps specifically designed for micro, small, and medium
            enterprises (MSMEs). The goal of these services is to help these
            businesses start, sustain, and grow through providing expert
            guidance, resources, and opportunities to connect with like-minded
            individuals and organizations.
          </p>
          <p>
            The Schemes, Programmes and Events showcased on this page are
            tailored specifically to cater to the needs of both Aspiring and
            Existing Entrepreneurs. To register for any of the programs offered
            on this website, the user can follow these simple steps:
          </p>
          <ul className="ml-3 mt-2">
            <li>
              <b style={{ color: "#9a3232" }}>
                Step 1: Click on the <a href="/user-management">Login</a> link and enter your credentials to
                log in to the website. 
              </b>
            </li>
            <li>
              <b style={{ color: "#9a3232" }}>
                Step 2: Once logged in, navigate to the specific
                program/event/scheme you are interested in and apply for it by
                following the instructions provided.
              </b>
            </li>
          </ul> */}
          {props.data.description}
        </div>
      </div>
    </div>
  );
}

export default InovationText;

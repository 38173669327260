import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import SchemeCard from "../../../../components/scheme.cards/scheme.card";
import { inovationData } from "../inovationData";

function InovationSchemes(props) {
  return (
    <div
      className=""
      style={{ backgroundColor: "#EFEFEF", paddingBottom: "68px" }}
    >
      <div className="head-container pb-5 pt-5 pl-4 pr-3">
        <div
          id="InovationSchemesHeading"
          className="d-flex justify-content-center align-items-center "
        >
          <div className="">
            <h2 className="inovation_headings m-1 ">Schemes</h2>
          </div>
        </div>

        <div className="mt-5 pt-3 col-12">
          <div className="d-flex justify-content-around align-items-center col-sm-12 p-0 ">
            <div className="row scheme_image col-md-8 col-sm-12">
              {props.data.map((card, i) => {
                return <SchemeCard data={card} key={i} index={i} />;
              })}
            </div>
            <div className="w-100 scheme_card_image d-none d-sm-none d-xs-none d-lg-block col-lg-5 col-md-4  d-flex align-items-center h-100">
              <KiedImages
                className="w-100 scheme_image_border"
                image="schemes-dec1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InovationSchemes;

import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./event.card.style.css";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import KiedButton from "../buttons/button/button.component";
import { useNavigate } from "react-router-dom";
function EventCard(props) {
  let width = useScreenBreakPoint();
  let navigate = useNavigate();
  
  const isSmallScreen = width === "sm" || width === "xs";
const isIndexGreaterThanTwo = props.index > 2;
const isIndexEven = (props.index + 1) % 2 === 0;
const isIndexModThreeOne = props.index % 3 === 1;

  return (
    <div
      className={`col-md-4 col-sm-12 event_card ${
        isSmallScreen
          ? (isIndexGreaterThanTwo && isIndexEven) || (!isIndexGreaterThanTwo && isIndexEven)
            ? "event_center_card "
            : ""
          : (isIndexGreaterThanTwo && isIndexModThreeOne) || (!isIndexGreaterThanTwo && isIndexModThreeOne)
            ? "event_center_card mt-5 "
            : "mt-5"
      }`}
    >
      <div className="d-flex flex-column align-items-center event_card_container">
        <div className="ribbon1">
          <span>{props?.data?.status}</span>
        </div>
        <div>
          <h3 className="event_title">{props?.data?.name}</h3>
        </div>
        <div className="d-flex mb-3 align-items-center">
          {/* <KiedImages
            className="event_icon"
            image={`${
              props.index > 2 //if index greater than 2
                ? props.index % 3 === 1
                  ? "location-bicon"
                  : "location-icon"
                : props.index % 3 === 1 //else index less than 2
                ? "location-bicon"
                : "location-icon"
            }`}
          /> */}
          <span className="event_location text_black ml-1">
            {props.data.mode}
          </span>
        </div>
        <div className="d-flex align-items-center ">
          <KiedImages className="mb-1 mr-1" image="calender-icon" />
          <span className="text_black">
            {props.data.event_start_date} {props.data.event_end_date}
          </span>
        </div>
        <div className="mt-4 ml-3 mb-3">
          {/* <KiedButton
            className="readmore_btn "
            primary
            rounded
            content={
              <a
                className="event_readmore"
                href={props.data.link + props.data.id}
              >
                Learn More
              </a>
            }
          /> */}
          
            <KiedButton
              className="readmore_btn "
              primary
              rounded
              content={
                <a
                  className="mt-4 ml-1 event_readmore"
                  onClick={(e) =>
                    navigate(`/events/${props.data.id}`)
                  }
                >
                  Learn More
                </a>
              }
            />
          
        </div>
      </div>
    </div>
  );
}

export default EventCard;

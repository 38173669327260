import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import KiedButton from "../buttons/button/button.component";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

function EventsListingCard(props) {
  const width = useScreenBreakPoint();
  const navigate = useNavigate();

  const NavToDetails = (id) => {
    if (props.type === "EventList") {
      navigate(`/events/${id}`);
    } else if (props.type === "ProgramList") {
      navigate(`/program/${id}`);
    } else {
      navigate(`/schemes/${id}`);
    }
  };

  return (
    <div
      className="event-card"
      style={{
        marginBottom: "16px",
        width: "100%",
        backgroundColor: "#fff",
        padding: "16px",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        {props.data.scheme_owner ? (
          <div
            style={{
              backgroundColor: "#F8463F",
              color: "#fff",
              padding: "8px 12px",
              borderRadius: "5px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {props.data.scheme_owner}
          </div>
        ) : (
          <div style={{ color: "#333", fontWeight: "bold" }}>
            {props.data.startTime}
          </div>
        )}

        {props.data.status && (
          <div
            style={{
              backgroundColor: "#F8463F",
              color: "#fff",
              padding: "8px 12px",
              borderRadius: "5px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <strong>{props.data.status}</strong>
          </div>
        )}

        <div style={{ width: "120px", height: "120px", overflow: "hidden" }}>
          <KiedImages
            className="event-image"
            link={props.data.listingImage}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>

        <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              gap: "8px",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <span>{props.data.title}</span>
            {props.data.location && (
              <span style={{ color: "#777" }}>@{props.data.location}</span>
            )}
          </div>

          <div
            style={{
              color: "#555",
              fontSize: "14px",
              marginTop: "4px",
              lineHeight: "1.5",
            }}
          >
            {props.data.cardDescription ? parse(props.data.cardDescription) : "--"}
          </div>
        </div>

        <KiedButton
          style={{
            backgroundColor: "#007BFF",
            color: "#fff",
            padding: "8px 16px",
            borderRadius: "4px",
            marginLeft: "auto",
          }}
          primary
          onClick={() => NavToDetails(props?.data?.id)}
          rounded
          content="Read More"
        />
      </div>
    </div>
  );
}

export default EventsListingCard;

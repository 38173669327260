import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ProgramsBanner(props) {
  let isAuthenticated = props.state.isAuthenticated;
  let userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;


  let width = useScreenBreakPoint();
  const history = useNavigate();
 
  const handleregister = () => {

   history("/department/inovation/edcregistration")
  };
 
  return (
    <div
      id="Banner_outer"
      className="banner_image banner_big d-flex justify-content-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-end"
         
        >
          <h2 className="text_banner more_link">{props.data.title}</h2>
          {props?.data?.title === "ARISE" ? (
            <div className="more_link">
              <div className="">
                <span className="prog-banner" style={{ color: "#fff" }}>
                  (Agro Incubation for Sustainable Entrepreneurship)
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="more_link">
            <div className="">
              <span className="prog-banner-2">{props.data.participants}</span>
            </div>
            {props?.data?.title === "BUSINESS GROWTH PROGRAM" ?
            <>
            <div className="mt-2">
            <span className="prog-banner-2">Last date for registration : 20th May 2023</span>
          </div></> : ""
}
          </div>
         

          {props?.data?.status === "Upcoming" && (
            <div className="more_link">
              <KiedButton
                onClick={() => handleregister()}
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center m-2">
                        <a
                          className="banner-link"
                         
                          target="_blank"
                        >
                          Click Here To Apply
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />
             
            </div>
          )}
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block"
        
        >
          <KiedImages
            className="program-banner-image d-none d-md-block"
            image="program-banner"
          />
        </div>
      </div>
    </div>
  );
}

export default ProgramsBanner;

import React from "react";

import NewProgramHero from "./sections/hero/newPrograms.hero";
import NewProgramOutcome from "./sections/ExpectedOutcome/newProgram.outcome";
import NewProgramDetails from "./sections/details/newprogram.details";
import NewProgramMentors from "./sections/mentors/newProgram.mentors";
import NewProgramSupportingPartners from "./sections/supportingPartners/newProgram.supporting";
import NewProgramFaq from "./sections/faq/newProgram.faq";
import NewProgramsReport from "./sections/reports/newprograms.reports";
import "./new.programs.styles.css";
import { json, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { inovationProgramData } from "../inovation/sections/inovationData";
import ProgramsBanner from "./sections/banner/programs.banner";
import NewProgrmGallery from "./sections/gallery/newProgram.gallery";
import AriseReport from "./sections/ariseReport/arise.report";
import { getProgramById } from "../../apis/publicApis";

function ProgramEnterpriseDetailPage() {
  const param = useParams();
  const [data, setData] = useState({});

 
  return (
    <div>
      <ProgramsBanner data={data} />
      <NewProgramHero data={data} />
   
      <NewProgramSupportingPartners data={data} />
    
    </div>
  );
}

export default ProgramEnterpriseDetailPage;

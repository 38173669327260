import React from "react";
import ActivitiesSection from "./sections/activities/activities.section";
import FaqSection from "./sections/faq/faq.section";

import ServiceSection from "./sections/services/service.section";
import { useOutletContext } from "react-router-dom";
import HeroSection from "./sections/hero/hero.section";
import NewsSection from "./sections/news/news.section";
import RegistrationSection from "./sections/registration/registration.section";
import TestimonialSection from "./sections/testimonial/testimonial.section";
import LoaderComponent from "../../components/loader/loader.component";
import { useState } from "react";
import { useEffect } from "react";
import DummyFlipcard from "./sections/flipcard/dummyflipcard";
import NewTestimonial from "./sections/testimonial/newtestimonial";
import EventsCarousel from "../../components/eventscarousel/eventscarousel";
import { getNews } from "../../apis/publicApis";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useLoginState } from "../../utils/zustand";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLangStore } from "../../utils/zustand";
import EventsRibbon from "../../components/eventsRibbon/eventscarousel";
import { logedUser } from "../../apis/auth.apis";

function HomePage() {
  const { language } = useLangStore();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  let props = useOutletContext();
  const needLogin = sessionStorage.getItem("loginRequired") || null;
  const loginState = useLoginState();

  const loginRef = useRef(null);

  const [data, setData] = useState([]);
  let navParam = useParams();
  const login = useRef();
  useEffect(() => {
    getNews()
      .then((res) => {
        let prdata = [];
        res?.data?.forEach((item) => {
          
          let prItem = {
            date: item?.date,
            photo: item?.photo,
            designation: item?.designation,
            title: item?.title,
            id: item?.id,
          };
          prdata.push(prItem);
        });
        setData(prdata);
        setIsActive(false);
      })
      .catch(() => {});
  }, []);
  const getUserData = () => {
    logedUser()
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.user));
        props.Login();
        loginState.resetUserType();
        setIsActive(false);
      })
      .catch((err) => {
        setIsActive(false);
        localStorage.clear();
        navigate("/");
       
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    if (loginState?.loginCount) {
      login.current.scrollIntoView({ behavior: "smooth" });
    }
  
  }, [loginState?.loginCount]);


  return (
    <div>
      <LoaderComponent open={isActive} {...props} />
      <HeroSection {...props} />

     \
      <div className="py-1  home_events_carousel">
        <EventsCarousel data={data} />
      </div>
    
      <div ref={login}>
    
        <RegistrationSection
          language={language}
          heading={
            language !== "label"
              ? "നിങ്ങളുടെ വിഭാഗം തിരഞ്ഞെടുക്കുക"
              : "Select your Category "
          }
          nextPath={"login"}
          {...props}
          ref={loginRef}
        />
      </div>

      <NewsSection
        heading={language !== "label" ? "വാർത്തകൾ" : "Latest News"}
        {...props}
        data={data}
        language={language === "label" ? "ma" : "en"}
      />
      {/* <NewTestimonial /> */}
      <TestimonialSection
        {...props}
        language={language === "label" ? "ma" : "en"}
      />
      <FaqSection
        heading={
          language !== "label" ? "ചോദ്യങ്ങൾ" : "Frequently Asked Questions"
        }
        language={language === "label" ? "ma" : "en"}
        {...props}
      />
    </div>
  );
}

export default HomePage;

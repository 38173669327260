import React from "react";
import { useState } from "react";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { Accordion } from "react-bootstrap";
import KiedButton from "../../../../components/buttons/button/button.component";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function NewProgramDetails(props) {
  const history = useNavigate();
  let width = useScreenBreakPoint();
 
  let userData = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

  const handleregister = () => {
    
    history("/department/inovation/edcregistration")
  };
 
  let objectivedata = props?.data?.objectives?.map((card, i) => {
    return (
      <li>
        <span>{card.objective}</span>
      </li>
    );
  });

  let programdata = props?.data?.structures?.map((card, i) => {
    return (
      <li>
        <span>{card.structure}</span>
      </li>
    );
  });

  const eligibledata = () => {
    return (
      <>
        {props?.data?.eligibilities?.listing?.map((card, i) => (
          <li>
            <span>{card.eligibility}</span>
          </li>
        ))}
        <div>
          {props?.data?.eligibilities?.sectorFocus && (
            <span>
              Sector Focus: 
              {props?.data?.eligibilities?.sectorFocus}
            </span>
          )}
        </div>
        <div>
          {props?.data?.eligibilities?.duration && (
            <span>
              Duration:
              {props?.data?.eligibilities?.duration}
            </span>
          )}
        </div>
        <div>
          {props?.data?.eligibilities?.batchSize && (
            <span>
            Batch Size: 
              {props?.data?.eligibilities?.batchSize}
            </span>
          )}
        </div>
      </>
    );
  };



  let feesdata = props?.data?.fees?.map((card, i) => {
    return (
      <li>
        <span>{card.fee}</span>
      </li>
    );
  });

  const [title, setTitle] = useState("Objectives");
  const [datatype, setDatatype] = useState(objectivedata);

  const handleobjectives = (e) => {
    setTitle("Objectives");
    setDatatype(objectivedata);
  };
  const handleobjectivesr = (e) => {
    if (title !== "Objectives") setTitle("Objectives");
    else setTitle(null);
    
    setDatatype(objectivedata);
  };
  const handleEligibility = (e) => {
    setTitle("Eligibility");
    setDatatype(eligibledata);
  };
  const handleEligibilityr = (e) => {
    if (title !== "Eligibility") setTitle("Eligibility");
    else setTitle(null);
   
    setDatatype(eligibledata);
  };
  const handleProgram = (e) => {
    setTitle("Selection Process");
    setDatatype(programdata);
  };
  const handleProgramr = (e) => {
    if (title !== "Selection Process") setTitle("Selection Process");
    else setTitle(null);
   
    setDatatype(programdata);
  };
  const handleFees = (e) => {
    setTitle("Fees");
    setDatatype(feesdata);
  };
  const handleFeesr = (e) => {
    if (title !== "Fees") setTitle("Fees");
    else setTitle(null);
   
    setDatatype(feesdata);
  };
  return width === "xs" || width === "sm" ? (
    <div className="auto-container responsiveProgramDetails">
      <Accordion
        defaultActiveKey="0"
        className=""
        style={{ marginTop: "45px" }}
      >
        <Accordion.Item eventKey="0" className="">
          <Accordion.Header
            onClick={() => handleobjectivesr()}
            className={title === "Objectives" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">Objectives</span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={title === "Objectives" ? "arrow-white" : "arrow-right"}
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer ">
              <ul>{datatype || objectivedata}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="">
          <Accordion.Header
            onClick={() => handleEligibilityr()}
            className={title === "Eligibility" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">Eligibility</span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={
                    title === "Eligibility" ? "arrow-white" : "arrow-right"
                  }
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer">
              <ul>{datatype}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="">
          <Accordion.Header
            onClick={() => handleProgramr()}
            className={title === "Selection Process" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">
              Selection Process
              </span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={
                    title === "Selection Process"
                      ? "arrow-white"
                      : "arrow-right"
                  }
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer">
              <ul>{datatype}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="">
          <Accordion.Header
            onClick={() => handleFeesr()}
            className={title === "Fees" ? "active" : ""}
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">Fees</span>
              <span className="d-flex align-items-center">
                <KiedImages
                  className="detailsButton"
                  image={title === "Fees" ? "arrow-white" : "arrow-right"}
                />
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="detail-description responsiveDetailContainer">
              <ul>{datatype}</ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  ) : (
    <div className="detail-container my-1">
      <div className="auto-container d-flex mt-5 mb-5">
        <div className=" col-4 buttonSection d-flex flex-column justify-content-start">
        
          <div>
            <button
              onClick={() => handleobjectives()}
              className={title === "Objectives" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Objectives</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={
                      title === "Objectives" ? "arrow-white" : "arrow-right"
                    }
                  />
                </span>
              </span>
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-around">
            <button
              onClick={() => handleEligibility()}
              className={title === "Eligibility" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Eligibility</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={
                      title === "Eligibility" ? "arrow-white" : "arrow-right"
                    }
                  />
                </span>
              </span>
            </button>
          </div>
          <div>
            <button
              onClick={() => handleProgram()}
              className={title === "Selection Process" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Selection Process</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={
                      title === "Selection Process"
                        ? "arrow-white"
                        : "arrow-right"
                    }
                  />
                </span>
              </span>
            </button>
          </div>
          <div className="lastButton">
            <button
              onClick={() => handleFees()}
              className={title === "Fees" ? "active" : ""}
            >
              <span className="d-flex align-items-center justify-content-between">
                <span>Fees</span>
                <span>
                  <KiedImages
                    className="detailsButton"
                    image={title === "Fees" ? "arrow-white" : "arrow-right"}
                  />
                </span>
              </span>
            </button>
          </div>
        </div>
        <div className="col-8 detailSection d-flex justify-content-end align-items-start">
          <div className="col-8">
            <h1 id="changingTitle" className="detail-title ">
              {title}
            </h1>
            <div className="detail-description">
              <ul>{datatype || objectivedata}</ul>
            </div>
          </div>
        </div>
      </div>
      {props?.data?.status === "Upcoming" && 
      <>
       <div>
          <div className="d-flex justify-content-center mt-5 mb-3">
            <KiedButton
              onClick={() => handleregister()}
              content={
                <>
                  <div className="d-flex p-1">
                    <div className="d-flex align-items-center">
                    <a
                        className="banner-link"
                        // href={props.data.titleLink}
                        target="_blank"
                      >
                        Click Here To Apply
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </div>
        </div>
      </>}
    </div>
  );
}

export default NewProgramDetails;
